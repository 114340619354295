/* eslint-disable complexity */

import {Item, ItemUncheckedCreateInput, ItemUncheckedUpdateInput, InspectionDrawing, ItemTask, ItemCount} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {TFunction} from 'i18next';
import {z} from 'zod';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  itemFieldErrors: Record<string, boolean>;
  deleteItemModalOpen: boolean;
  editItem?: Partial<Item> | null;
  updateItemData?: ItemUncheckedUpdateInput | ItemUncheckedCreateInput;
  activeItem?: Partial<Item>;
  activeInspectionDrawing?: Partial<InspectionDrawing>;
  taskToDelete?: Partial<ItemTask>;
  newTaskData?: Partial<ItemTask>;
  fetchItemTasks?: () => void;
};

const initialState: State = {
  deleteItemModalOpen: false,
  editItem: null,
  updateItemData: {},
  itemFieldErrors: {},
  activeItem: undefined,
  activeInspectionDrawing: undefined,
  taskToDelete: undefined,
  newTaskData: undefined,
  fetchItemTasks: undefined,
};

export const MAX_LENGTH_VALIDATORS = {
  DESCRIPTION: 50,
  LONG_DESCRIPTION: 500,
  ITEM_NUMBER: 10,
  CALL_HORIZON: 10,
};

export const ZOD_ITEM_DATAS = (t: TFunction) => ({
  // eslint-disable-next-line camelcase
  itemNumber: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.ITEM_NUMBER).min(1, {message: t('message.error.form.required')}),
  // eslint-disable-next-line camelcase
  typeId: z.number({required_error: t('message.error.form.required')}),
  // eslint-disable-next-line camelcase
  description: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, {message: t('message.error.form.required')}),
  longDescription: z.string().max(MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION).optional(),
  // eslint-disable-next-line camelcase
  period: z.number({required_error: t('message.error.form.required')}),
  // // eslint-disable-next-line camelcase
  // levelPreparation: z.number({required_error: t('message.error.form.required')}).min(1).max(2),
  // // eslint-disable-next-line camelcase
  // levelReporting: z.number({required_error: t('message.error.form.required')}).min(1).max(2),
  plannerGroupId: z.number().optional(),
  mainWorkCenterId: z.number().optional(),
});

type Actions = {
  updateItem: (item: Partial<Item>, isNew?: boolean) => void;
  deleteItem: (item?: Partial<Item>) => void;
  setUpdateItemData: (updateItemData: ItemUncheckedUpdateInput | ItemUncheckedCreateInput) =>void;
  setEditItem: (item?: Partial<Item> | null) => void;
  updateItemDataField: (field: string, value: unknown) => void;
  setActiveItem: (activeItem?: Partial<Item>) => void;
  changeDeleteItemModalDisplay: (isOpen: boolean) => void;
  isSaved: () => boolean;
  hasError: ()=>boolean;
  updateItemFieldError: (field: string, value: boolean) => void;
  hasFieldError: (field: string, forceCheck?: boolean)=>boolean;
  cancelEditData: () => void;
  resetData: () => void;
  setTaskToDelete: (taskToDelete?: Partial<ItemTask>) => void;
  deleteItemTask: (taskToDelete?: Partial<ItemTask>, item?: Partial<Item>) => void;
  setNewTaskData: (newTaskData?: Partial<ItemTask>) => void;
  updateItemTask: (taskToUpdate: Partial<ItemTask>, item?: Partial<Item>) => void;
  setFetchItemTasks: (fetchItemTasks?: () => void) => void;
};

type ItemState = State & Actions;

const getItemUpdateState = (state: Partial<State>, itemChanges: Partial<Item>) : Partial<ItemState> => {
  const newActiveItem: Partial<Item> = {...state.activeItem, ...itemChanges};
  return {
    activeItem: newActiveItem,
    editItem: {...newActiveItem},
  };
};

const useItemStore = create<ItemState>()(
  immer((set, get) => ({
    ...initialState,
    setFetchItemTasks(fetchItemTasks) {
      set({fetchItemTasks});
    },
    updateItemFieldError: (field: string, value: boolean) => set(state => {
      state.itemFieldErrors = {
        ...state.itemFieldErrors,
        [field]: value,
      };
    }),
    updateItemTask: (taskToUpdate: Partial<ItemTask>, item?: Partial<Item>) => set(state => {
      const relatedItem: Partial<Item>|undefined|null = item ?? state.activeItem;
      const newState: Partial<State> = {};
      if (relatedItem) {
        const relatedItemTaskIdx = relatedItem?.itemTasks?.findIndex((item: Partial<ItemTask>) => item.id! === taskToUpdate.id!) ?? -1;
        if (relatedItemTaskIdx >= 0) {
          const itemTasks = [...relatedItem.itemTasks!];
          itemTasks[relatedItemTaskIdx] = {
            ...relatedItem.itemTasks![relatedItemTaskIdx],
            ...taskToUpdate,
          };
          if (relatedItem.id === state.activeItem?.id) {
            Object.assign(newState, getItemUpdateState(get(), {
              itemTasks,
            }));
          } else {
            relatedItem.itemTasks = itemTasks;
          }
        }
      }

      return newState;
    }),
    setNewTaskData(newTaskData) {
      set({newTaskData});
    },
    setTaskToDelete: (taskToDelete?: Partial<ItemTask>) => set({
      taskToDelete,
    }),
    deleteItemTask: (taskToDelete?: Partial<ItemTask>, item?: Partial<Item>) => set(state => {
      const relatedItem: Partial<Item>|undefined = item ?? state.activeItem;
      const deletedTask: Partial<ItemTask> | undefined = {...(taskToDelete ?? state.taskToDelete)};
      const newState: Partial<State> = {};
      if (relatedItem && deletedTask) {
        if (deletedTask.id === state.taskToDelete?.id) {
          newState.taskToDelete = undefined;
        }

        const itemTasks = relatedItem.itemTasks?.filter((item: ItemTask) => item.id !== deletedTask.id!);

        if (relatedItem.id === state.activeItem?.id) {
          Object.assign(newState, getItemUpdateState(get(), {
            itemTasks,
            _count: {
              ...state.activeItem!._count,
              itemTasks: itemTasks?.length ?? 0,
            } as ItemCount,
          }));
        } else {
          relatedItem.itemTasks = itemTasks;
        }
      }

      return newState;
    }),
    cancelEditData: () => set(state => {
      const newState: Partial<State> = {
        updateItemData: {},
        itemFieldErrors: {},
        editItem: {...state.activeItem},
      };
      if (state.activeItem?.id === OBJ_NEW_ID) {
        newState.activeItem = undefined;
        newState.editItem = undefined;
      }

      return newState;
    }),
    resetData() {
      set({
        ...initialState,
      });
    },
    isSaved() {
      const state = get();
      if (state.activeItem) {
        return !(state.updateItemData && Object.keys(state.updateItemData).length > 0);
      }

      return true;
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get();
      if (state.activeItem) {
        if (!Object.keys(state.updateItemData ?? {}).includes(field) && !forceCheck) {
          return false;
        }

        if (state.itemFieldErrors?.[field] === true) {
          return true;
        }

        let value;
        switch (field) {
          case 'itemNumber':
            const itemNumber = (state.editItem?.itemNumber ?? '').trim();
            return itemNumber.length > MAX_LENGTH_VALIDATORS.ITEM_NUMBER || itemNumber === '';
          case 'callHorizon':
            const callHorizon = String(state.editItem?.callHorizon ?? '').trim();
            return callHorizon !== '' && isNaN(parseInt(callHorizon, 10));
          case 'levelReporting':
          case 'levelPreparation':
            value = String(state.editItem?.[field] ?? '').trim();
            return value === '' || isNaN(parseInt(value, 10)) || ![1, 2].includes(parseInt(value, 10));
          case 'period':
            value = String(state.editItem?.[field] ?? '').trim();
            return value === '' || isNaN(parseInt(value, 10));
          case 'description':
            const description = (state.editItem?.description ?? '').trim();
            return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '';
          case 'longDescription':
            const longDescription = (state.editItem?.longDescription ?? '').trim();
            return longDescription.length > MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION;
          case 'typeId':
            return !state.editItem?.typeId;
          default:
            break;
        }
      }

      return false;
    },
    hasError() {
      const state = get();
      if (state.activeItem) {
        return ['description', 'callHorizon', 'itemNumber', 'period', 'longDescription', 'typeId', 'levelReporting', 'levelPreparation'].some((field: string) => state.hasFieldError(field, true));
      }

      return false;
    },
    setActiveItem: activeItem => set({
      activeItem,
      editItem: activeItem ? {...activeItem} : undefined,
      updateItemData: activeItem?.id === OBJ_NEW_ID ? {planId: activeItem.planId!, mainWorkCenterId: activeItem.mainWorkCenterId, plannerGroupId: activeItem.plannerGroupId} as ItemUncheckedCreateInput : undefined,
      itemFieldErrors: undefined,
    }),
    updateItemDataField: (field: string, value: unknown) => set(state => {
      const val = ['period', 'levelReporting', 'levelPreparation', 'callHorizon'].includes(field) ? (String(value).trim() !== '' ? parseInt(String(value).trim(), 10) : null) : value;

      return {
        updateItemData: {
          ...state.updateItemData,
          [field]: state.activeItem?.id === OBJ_NEW_ID ? val : {
            set: val,
          },
        },
        itemFieldErrors: {
          ...state.itemFieldErrors,
          [field]: false,
        },
      };
    }),
    setUpdateItemData(updateItemData: ItemUncheckedUpdateInput | ItemUncheckedCreateInput) {
      set({updateItemData});
    },
    setEditItem(editItem) {
      set({editItem});
    },
    updateItem: (item: Partial<Item>, isNew?: boolean) => set(_state => {
      const {activeItem} = get() ?? {};
      const itemId: number = isNew ? OBJ_NEW_ID : item.id!;
      const newState: Partial<State> = {};

      if (activeItem && itemId === activeItem.id!) {
        newState.activeItem = {
          ...activeItem,
          ...item,
        };
        newState.editItem = {...newState.activeItem};
      }

      return newState;
    }),
    deleteItem: (item?: Partial<Item>) => set(state => {
      const deletedItem: Partial<Item>|undefined|null = item ?? state.activeItem;
      const newState: Partial<State> = {};
      if (deletedItem) {
        newState.deleteItemModalOpen = false;
        if (deletedItem === state.activeItem) {
          newState.activeItem = undefined;
        }
      }

      return newState;
    }),
    changeDeleteItemModalDisplay: (isOpen: boolean) => set({
      deleteItemModalOpen: isOpen,
    }),
  })),
);

export default useItemStore;
