import {useLazyQuery} from '@apollo/client';
import MeasurementValueContainer from '@app/components/Common/Block/ItemCard/MeasurementValueContainer';
import {EventInspectionPoint, GetLatestEventInspectionPointByInspIdQuery, GetLatestEventInspectionPointWithQualReadByInspIdQuery, InspectionPoint, RefMeasPointValCode} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID, WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_WITH_QUALREAD_BY_INSP_ID} from '@app/graphql/requests';
import useInspectionPointStore from '@app/stores/insp';
import {QUALREADING_COLOR} from '@app/utils/constants';
import {isValidUnit} from '@app/utils/functions';
import {
  RadCard,
  RadCardContent,
  RadContextMenu,
  RadContextMenuContent,
  RadContextMenuItem,
  RadContextMenuTrigger,
} from '@holis/react-ui/rad';
import {MouseEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuCamera, LuExternalLink} from 'react-icons/lu';

type TInspectionPointItem = Readonly<{
  inspectionPoint: Partial<InspectionPoint>;
  eventInspectionPoint?: Partial<EventInspectionPoint>;
  readonly?: boolean;
  hasMenuContext?: boolean;
  onClick?: (e: MouseEvent) => void;
}>;

export default function InspectionPointItem({inspectionPoint, eventInspectionPoint, hasMenuContext, onClick}: TInspectionPointItem) {
  // const {setInspectionPointToDelete} = useEventStore();
  const [evtInsp, setEvtInsp] = useState<Partial<EventInspectionPoint | null>>();
  const {setActiveInspectionPoint} = useInspectionPointStore();
  const [getLatestEventCmlByCmlIdApi] = useLazyQuery<GetLatestEventInspectionPointByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID);
  const [qualReading, setQualReading] = useState<Partial<RefMeasPointValCode> | null>();
  const [getLatestEventInspWithQualReadByInspIdApi] = useLazyQuery<GetLatestEventInspectionPointWithQualReadByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_WITH_QUALREAD_BY_INSP_ID);

  const {t} = useTranslation();

  useEffect(() => {
    if (!eventInspectionPoint) {
      getLatestEventCmlByCmlIdApi({
        variables: {
          inspId: inspectionPoint.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setEvtInsp(queryResult.data?.findFirstEventInspectionPoint as Partial<EventInspectionPoint>)).catch(setEvtInsp);
    } else {
      setEvtInsp(eventInspectionPoint);
    }
  }, [eventInspectionPoint]);

  useEffect(() => {
    if (typeof evtInsp !== 'undefined') {
      setQualReading(evtInsp?.qualReading ?? null);
    }
  }, [evtInsp]);

  useEffect(() => {
    if (!eventInspectionPoint && evtInsp?.id && (!evtInsp?.qualReading || evtInsp.qualReading.codeCond === '00')) {
      getLatestEventInspWithQualReadByInspIdApi({
        variables: {
          inspId: inspectionPoint!.id,
          id: evtInsp!.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        if (queryResult.data?.findFirstEventInspectionPoint) {
          setQualReading((queryResult.data?.findFirstEventInspectionPoint as EventInspectionPoint).qualReading ?? null);
        }
      });
    }
  }, [evtInsp]);

  return (
    <RadContextMenu key={`inspection-point-${inspectionPoint.id}`}>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <RadCard className={'rounded-md' + (hasMenuContext ? ' cursor-pointer hover:bg-muted' : '')}>
          <RadCardContent className='flex p-2 gap-2 items-center  min-h-[66px]' onClick={hasMenuContext && onClick ? onClick : undefined}>
            <div className='self-stretch rounded-md bg-blue-50 p-2 min-w-14 text-center'>
              <div className='text-primary font-extrabold'>
                {inspectionPoint?.class?.class}
              </div>

              <div className='font-extrabold'>
                {inspectionPoint?.position}
              </div>
            </div>

            <div className='flex-1'>
              <div className='font-medium'>
                {inspectionPoint?.description}
              </div>

              <div className='font-medium text-2xs'>
                {inspectionPoint?.functionalLocation?.floc}
              </div>
            </div>

            <div className='self-stretch flex gap-2 items-center'>
              {(!!inspectionPoint._count?.pictures || !!eventInspectionPoint?.inspectionPoint?._count?.pictures) && (
                <LuCamera className='text-primary' size={20}/>
              )}
              <MeasurementValueContainer
                className='self-stretch'
                measurementValue={isValidUnit(inspectionPoint.char?.unit) && typeof evtInsp?.quantReading === 'string' && evtInsp?.quantReading !== '' ? evtInsp?.quantReading : qualReading?.valCode} measurementColor={(qualReading?.integrityCondition?.color ?? QUALREADING_COLOR) as string}/>
              {/* <div
                className='self-stretch rounded-md p-2 bg-gray-200 min-w-14 text-center flex flex-col justify-center'
                style={{
                  backgroundColor: (qualReading?.integrityCondition?.color ?? QUALREADING_COLOR) as string,
                }}
              >
                <div className='font-medium'>
                  {(isValidUnit(inspectionPoint.char?.unit) ? evtInsp?.quantReading : evtInsp?.qualReading?.valCode) ?? '-'}
                </div>
              </div> */}
            </div>
          </RadCardContent>
        </RadCard>
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveInspectionPoint(inspectionPoint)}>
          <LuExternalLink className='mr-2'/>

          {t('label.open')}
        </RadContextMenuItem>

        {/* <RadContextMenuItem
          className='item-destructive'
          disabled={readonly}
          onClick={() => setInspectionPointToDelete(eventInspectionPoint!)}
        >
          <LuTrash2 className='mr-2'/>

          {t('label.removeFromEvent')}
        </RadContextMenuItem> */}
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
