/* eslint-disable camelcase */
/* eslint-disable complexity */
import {ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions, OperationVariables} from '@apollo/client';
import {SortableItem} from '@app/components/Common/List/SortableList';
import {Attachment, Cml, CreateEventWorkflowMutation, Damage, Document, Event, EventCml, EventCount, EventDamage, EventDocument, EventFloc, EventInspectionDrawing, EventInspectionPoint, EventTask, EventUncheckedCreateInput, EventUncheckedUpdateInput, FunctionalLocation, FunctionalLocationUncheckedUpdateInput, InspectionPoint, IntegrityStatus, Notification, Picture} from '@app/graphql/__types__/graphql';
import {TObjectDrawingViewerAction, TObjectDrawingViewerState} from '@app/types/app';
import {ECmlFilter, EDamageFilter, EEventFlocObjectFilter, EGenerateWorkpackBtnError, EWorkpackLeftSideTab, EWorkpackRightSideTab, EWORKORDER_REF_EVENT_TASK_CATEGORY, EWORKORDER_EVENT_STATUS, ENOTIFICATION_NOTIF_STATUS} from '@app/utils/enums';
import {convertDateDefaultStrToDateObj} from '@app/utils/functions/dates';
import {EventWorkflowActionKey, getNextEventStatus, getPrevEventStatus} from '@app/utils/functions/workflows';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {generateCommonObjectDrawingStore} from '@app/utils/functions/stores';
import {InterpolationMap, TFunction} from 'i18next';
import {z} from 'zod';
import {OBJ_NEW_ID} from '@app/utils/constants';
export const TAB_CMLS = 0;
export const TAB_DAMAGES = 1;
export const CML_PREFIX = 'cml-';
export const DAMAGE_PREFIX = 'dmg-';

export const ZOD_EVENT_DATAS = (t: TFunction) => ({

  event: z.string({required_error: t('message.error.form.required')}).min(1, {message: t('message.error.form.required')}),

  description: z.string({required_error: t('message.error.form.required')}).min(1, {message: t('message.error.form.required')}),
  code: z.string().optional().nullable(),

});

export const ZOD_CREATE_EVENT_DATAS = (t: TFunction) => ({
  description: z.string({required_error: t('message.error.form.required')}).min(1, {message: t('message.error.form.required')}),
  typeId: z.number({required_error: t('message.error.form.required')}),
  plannedDate: z.date({required_error: t('message.error.form.required')}),
  schedulingTagId: z.number({required_error: t('message.error.form.required')}),
  plannerGroupId: z.number().optional(),
  mainWorkCenterId: z.number().optional(),
});

// All state in inspection drawing page (list, detail modal, cml modal, damage modal)
type State = TObjectDrawingViewerState & {
  events?: Partial<Event>[];
  activeEvent?: Partial<Event>;
  deleteEventModalOpen: boolean;
  editEvent?: Partial<Event>;
  uploadFile?: File;
  updateData?: EventUncheckedCreateInput | EventUncheckedUpdateInput;
  updateSchedulingTagData?: FunctionalLocationUncheckedUpdateInput;
  fieldErrors: Record<string, boolean>;
  schedulingTagFieldErrors: Record<string, boolean>;
  leftSideWidth?: number;
  leftSideTabSelectedValue: EWorkpackLeftSideTab;
  flocToDelete?: Partial<EventFloc>;
  inspectionPointToDelete?: Partial<EventInspectionPoint>;
  taskToDelete?: Partial<EventTask>;
  newTaskData?: Partial<EventTask>;
  syncEventFromDB?: boolean;
  eventInspectionDrawings?: Partial<EventInspectionDrawing>[];
  eventCmls?: Partial<EventCml>[];
  eventFlocCmls?: Partial<Cml>[];
  eventDamages?: Partial<EventDamage>[];
  eventFlocDamages?: Partial<Damage>[];
  eventInspectionPoints?: Partial<EventInspectionPoint>[];
  eventFlocInspectionPoints?: Partial<InspectionPoint>[];
  inspectionDrawingSortChangeCallback?: (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => void;
  fetchEventInspectionDrawings?: () => void;
  fetchEventCmls?: () => void;
  fetchEventDamages?: () => void;
  fetchEventInspectionPoints?: () => void;
  fetchEventNotifications?: () => void;
  modalHidden: boolean;
  eventDocuments?: Partial<EventDocument>[];
  documentToDelete?: Partial<EventDocument>;
  filteredEventCmls?: Partial<EventCml>[];
  filteredEventFlocCmls?: Partial<Cml>[];
  filteredEventDamages?: Partial<EventDamage>[];
  filteredEventFlocDamages?: Partial<Damage>[];
  cmlActiveFilter: ECmlFilter[];
  cmlSearchInput: string;
  fetchEvents?: () => void;
  eventFlocCmlFilter: EEventFlocObjectFilter;
  eventFlocDamageFilter: EEventFlocObjectFilter;
  eventFlocInspectionPointFilter: EEventFlocObjectFilter;
  eventFlocDocumentFilter: EEventFlocObjectFilter;
  fetchEventFlocCmls?: () => void;
  fetchEventFlocDamages?: () => void;
  fetchEventFlocInspectionPoints?: () => void;
  eventFlocs?: Partial<EventFloc>[];
  fetchEventDocuments?: () => void;
  fetchEventFlocDocuments?: () => void;
  fetchEventFlocNotifications?: () => void;
  eventFlocDocuments?: Partial<Document>[];
  filteredEventDocuments?: Partial<EventDocument>[];
  filteredEventFlocDocuments?: Partial<Document>[];
  eventFlocIds?: number[];
  fetchEventFlocs?: () => void;
  eventWorkflowModalDisplayed: boolean;
  createEventWorkflowFunc?: (options?: MutationFunctionOptions<CreateEventWorkflowMutation, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined) => Promise<FetchResult<CreateEventWorkflowMutation>>;
  attachmentToDelete?: Partial<Attachment>;
  eventAttachments?: Partial<Attachment>[];
  fetchEventAttachments?: () => void;
  eventTasks?: Partial<EventTask>[];
  fetchEventTasks?: () => void;
  eventFlocNotificationFilter: EEventFlocObjectFilter;
  eventNotifications?: Partial<Notification>[];
  eventFlocNotifications?: Partial<Notification>[];
  pictures?: Partial<Picture>[];
  fetchPictures?: () => void;
  cmlSelectionModalDisplayed: boolean;
  documentSelectionModalDisplayed: boolean;
  assignModalOpened: boolean;
  idwgSelectionModalDisplayed: boolean;
  pointFlocIds?: number[];
  pointIdwgIds?: number[];
  actionConfirmModalOpened: boolean;
  actionConfirmModalActionKey?: EventWorkflowActionKey;
  actionConfirmModalParams?: InterpolationMap<string>;
  actionConfirmModalCallback?: (cancel: boolean, comment?: string,) => void;
  eventValidationWarnings?: string[];
  eventValidationErrors?: string[];
  eventIntegrityStatuses?: Partial<IntegrityStatus>[];
};

const initialState: State = {
  activeEvent: undefined,
  deleteEventModalOpen: false,
  editEvent: undefined,
  uploadFile: undefined,
  updateData: {},
  updateSchedulingTagData: {},
  fieldErrors: {},
  schedulingTagFieldErrors: {},
  leftSideWidth: undefined,
  rightSideWidth: undefined,
  leftSideTabSelectedValue: EWorkpackLeftSideTab.EVENT_INFO,
  rightSideTabSelectedValue: EWorkpackRightSideTab.OVERVIEW,
  flocToDelete: undefined,
  inspectionPointToDelete: undefined,
  taskToDelete: undefined,
  newTaskData: undefined,
  syncEventFromDB: undefined,
  eventInspectionDrawings: undefined,
  eventCmls: undefined,
  eventDamages: undefined,
  eventInspectionPoints: undefined,
  selectedDrawing: undefined,
  inspectionDrawingSortChangeCallback: undefined,
  modalHidden: false,
  eventDocuments: undefined,
  documentToDelete: undefined,
  filteredEventCmls: undefined,
  filteredEventDamages: undefined,
  cmlActiveFilter: [ECmlFilter.ACTIVE],
  cmlSearchInput: '',
  damageActiveFilter: [EDamageFilter.ACTIVE],
  damageSearchInput: '',
  fetchEvents: undefined,
  eventFlocCmls: undefined,
  eventFlocDamages: undefined,
  eventFlocInspectionPoints: undefined,
  eventFlocCmlFilter: EEventFlocObjectFilter.EVENT,
  eventFlocDamageFilter: EEventFlocObjectFilter.EVENT,
  eventFlocInspectionPointFilter: EEventFlocObjectFilter.EVENT,
  eventFlocDocumentFilter: EEventFlocObjectFilter.EVENT,
  eventFlocNotificationFilter: EEventFlocObjectFilter.EVENT,
  eventFlocs: undefined,
  fetchEventDocuments: undefined,
  fetchEventFlocDocuments: undefined,
  filteredEventDocuments: undefined,
  filteredEventFlocDocuments: undefined,
  filteredEventFlocCmls: undefined,
  filteredEventFlocDamages: undefined,
  eventFlocDocuments: undefined,
  fetchEventCmls: undefined,
  fetchEventDamages: undefined,
  fetchEventFlocCmls: undefined,
  fetchEventFlocDamages: undefined,
  fetchEventFlocInspectionPoints: undefined,
  fetchEventInspectionDrawings: undefined,
  fetchEventInspectionPoints: undefined,
  eventFlocIds: undefined,
  fetchEventFlocs: undefined,
  eventWorkflowModalDisplayed: false,
  createEventWorkflowFunc: undefined,
  attachmentToDelete: undefined,
  fetchEventAttachments: undefined,
  eventTasks: undefined,
  fetchEventTasks: undefined,
  eventNotifications: undefined,
  eventFlocNotifications: undefined,
  fetchEventFlocNotifications: undefined,
  fetchEventNotifications: undefined,
  fetchObjectDrawings: undefined,
  lastCmlUpdated: undefined,
  lastCmlUpdatedAt: undefined,
  lastDamageUpdated: undefined,
  lastDamageUpdatedAt: undefined,
  pictures: undefined,
  fetchPictures: undefined,
  cmlSelectionModalDisplayed: false,
  damageSelectionModalDisplayed: false,
  documentSelectionModalDisplayed: false,
  assignModalOpened: false,
  idwgSelectionModalDisplayed: false,
  pointFlocIds: undefined,
  pointIdwgIds: undefined,
  actionConfirmModalOpened: false,
  eventValidationErrors: [],
  eventValidationWarnings: [],
};

export const MAX_LENGTH_VALIDATORS = {
  EVENT: 45,
  DESCRIPTION: 50,
  CODE: 10,
  BATCH_NUMBER: 30,
  NOTES: 500,
  LONG_DESCRIPTION: 500,
  REPORT_SUMMARY: 5000,
};

type Actions = TObjectDrawingViewerAction & {
  setEvents: (events?: Partial<Event>[]) => void;
  setActiveEvent: (activeEvent?: Partial<Event>) => void;
  setUpdateData: (updateData: EventUncheckedCreateInput | EventUncheckedUpdateInput) => void;
  setUpdateSchedulingTagData: (updateData: FunctionalLocationUncheckedUpdateInput) => void;
  isSaved: () => boolean;
  hasError: (excludindFields?: string[], fields?: string[]) => boolean;
  hasFieldError: (field: string, forceCheck?: boolean) => boolean;
  changeDeleteEventModalDisplay: (isOpen: boolean) => void;
  deleteEvent: () => void;
  cancelEditData: () => void;
  updateEvent: (event: Partial<Event>) => void;
  updateFieldError: (field: string, value: boolean) => void;
  setEditEvent: (editEvent?: Partial<Event>) => void;
  updateDataField: (field: string, value: unknown) => void;
  updateSchedulingTagDataField: (field: string, value: unknown) => void;
  resetData: () => void;
  setLeftSideWidth: (leftSideWidth?: number) => void;
  canShowGenerateWorkpackBtn: () => boolean;
  canShowValidateWorkpackBtn: () => boolean;
  canShowRejectWorkpackBtn: () => boolean;
  getGenerateWorkpackBtnDisabledError: () => EGenerateWorkpackBtnError | null;
  setLeftSideTabSelectedValue: (leftSideTabSelectedValue: EWorkpackLeftSideTab) => void;
  setFlocToDelete: (flocToDelete?: Partial<EventFloc>) => void;
  setInspectionPointToDelete: (inspectionPointToDelete?: Partial<EventInspectionPoint>) => void;
  setTaskToDelete: (taskToDelete?: Partial<EventTask>) => void;
  deleteEventFloc: (flocToDelete?: Partial<EventFloc>, event?: Partial<Event>) => void;
  deleteEventTask: (taskToDelete?: Partial<EventTask>, event?: Partial<Event>) => void;
  updateEventTask: (taskToUpdate: Partial<EventTask>, event?: Partial<Event>) => void;
  setNewTaskData: (newTaskData?: Partial<EventTask>) => void;
  setSyncEventFromDB: (syncEventFromDB?: boolean) => void;
  setEventInspectionDrawings: (eventInspectionDrawings: Partial<EventInspectionDrawing>[]) => void;
  setEventCmls: (eventCmls: Partial<EventCml>[]) => void;
  setEventDamages: (eventDamages: Partial<EventDamage>[]) => void;
  setEventInspectionPoints: (eventInspectionPoints: Partial<EventInspectionPoint>[]) => void;
  setInspectionDrawingSortChangeCallback: (inspectionDrawingSortChangeCallback: (dragItem: SortableItem, dropItem: SortableItem, dropIndex: number) => void) => void;
  setFetchEventInspectionDrawings: (fetchEventInspectionDrawings?: () => void) => void;
  setModalHidden: (modalHidden: boolean) => void;
  setFetchEventInspectionPoints: (fetchEventInspectionPoints?: () => void) => void;
  setFetchEventCmls: (fetchEventCmls?: () => void) => void;
  setFetchEventDamages: (fetchEventDamages?: () => void) => void;
  setFetchEventFlocInspectionPoints: (fetchEventFlocInspectionPoints?: () => void) => void;
  setFetchEventFlocCmls: (fetchEventFlocCmls?: () => void) => void;
  setFetchEventFlocDamages: (fetchEventFlocDamages?: () => void) => void;
  setEventDocuments: (eventDocuments?: Partial<EventDocument>[]) => void;
  setDocumentToDelete: (documentToDelete?: Partial<EventDocument>) => void;
  setFilteredEventCmls: (filteredEventCmls?: Partial<EventCml>[]) => void;
  setFilteredEventDamages: (filteredEventDamages?: Partial<EventDamage>[]) => void;
  setCmlActiverFilter: (cmlActiveFilter?: ECmlFilter[]) => void;
  setCmlSearchInput: (cmlSearchInput: string) => void;
  setFetchEvents: (fetchEvents?: () => void) => void;
  setEventFlocCmls: (eventFlocCmls?: Partial<Cml>[]) => void;
  setEventFlocDamages: (eventFlocDamages?: Partial<Damage>[]) => void;
  setEventFlocInspectionPoints: (eventFlocInspectionPoints?: Partial<InspectionPoint>[]) => void;
  changeEventFlocCmlFilter: (eventFlocCmlFilter: EEventFlocObjectFilter) => void;
  changeEventFlocDamageFilter: (eventFlocDamageFilter: EEventFlocObjectFilter) => void;
  changeEventFlocInspectionPointFilter: (eventFlocInspectionPointFilter: EEventFlocObjectFilter) => void;
  changeEventFlocDocumentFilter: (eventFlocDocumentFilter: EEventFlocObjectFilter) => void;
  changeEventFlocNotificationFilter: (eventFlocNotificationFilter: EEventFlocObjectFilter) => void;
  setFilteredEventFlocCmls: (filteredEventFlocCmls?: Partial<Cml>[]) => void;
  setFilteredEventFlocDamages: (filteredEventFlocDamages?: Partial<Damage>[]) => void;
  setEventFlocs: (eventFlocs?: Partial<EventFloc>[]) => void;
  setFetchEventDocuments: (fetchEventDocuments?: () => void) => void;
  setFetchEventFlocDocuments: (fetchEventFlocDocuments?: () => void) => void;
  setEventFlocDocuments: (eventFlocDocuments?: Partial<Document>[]) => void;
  setFilteredEventFlocDocments: (filteredEventFlocDocuments?: Partial<Document>[]) => void;
  setFilteredEventDocuments: (filteredEventDocuments?: Partial<EventDocument>[]) => void;
  setEventFlocIds: (eventFlocIds?: number[]) => void;
  setFetchEventFlocs: (fetchEventFlocs?: () => void) => void;
  changeWorkflowModalDisplay: (eventWorkflowModalDisplayed: boolean) => void;
  setCreateEventWorkflowFunc: (createEventWorkflowFunc?: (options?: MutationFunctionOptions<CreateEventWorkflowMutation, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined) => Promise<FetchResult<CreateEventWorkflowMutation>>) => void;
  setAttachmentToDelete: (attachmentToDelete?: Partial<Attachment>) => void;
  setFetchEventAttachments: (fetchEventAttachments?: () => void) => void;
  setEventTasks: (eventTasks?: Partial<EventTask>[]) => void;
  setFetchEventTasks: (fetchEventTasks?: () => void) => void;
  setEventNotifications: (eventNotifications?: Partial<Notification>[]) => void;
  setEventFlocNotifications: (eventFlocNotifications?: Partial<Notification>[]) => void;
  setFetchEventNotifications: (fetchEventNotifications?: () => void) => void;
  setFetchEventFlocNotifications: (fetchEventFlocNotifications?: () => void) => void;
  setPictures: (pictures?: Partial<Picture>[]) => void;
  setFetchPictures: (fetchPictures?: () => void) => void;
  changeCmlSelectionModalDisplay: (cmlSelectionModalDisplayed: boolean) => void;
  changeDocumentSelectionModalDisplay: (documentSelectionModalDisplayed: boolean) => void;
  setAssignModalOpened: (assignModalOpened: boolean) => void;
  changeIdwgSelectionModalDisplay: (idwgSelectionModalDisplayed: boolean) => void;
  setPointFlocIds: (pointFlocIds?: number[]) => void;
  setPointIdwgIds: (pointIdwgIds?: number[]) => void;
  setActionConfirmModalOpened: (confirmModalOpened: boolean, confirmModalActionKey?: EventWorkflowActionKey, callback?: (cancel: boolean, comment?: string) => void, params?: InterpolationMap<string>) => void;
  checkActiveEventReportingValidity: (t: TFunction) => void;
  checkActiveEventPreparationValidity: (t: TFunction) => void;
  resetActiveEventValidation: () => void;
  setEventAttachments: (attachments: Partial<Attachment>[]) => void;
  setEventIntegrityStatuses: (integrityStatuses: Partial<IntegrityStatus>[]) => void;
};

const getEventUpdateState = (state: Partial<State>, eventChanges: Partial<Event>): Partial<State> => {
  const newActiveEvent: Partial<Event> = {...state.activeEvent, ...eventChanges};
  const newState: Partial<State> = {};
  newState.activeEvent = newActiveEvent;
  newState.editEvent = {
    ...newState.activeEvent,
  };

  return newState;
};

export type EventState = State & Actions;

export const useEventStore = create<EventState>()(
  immer((set, get) => ({
    ...initialState,
    ...generateCommonObjectDrawingStore(set, get),
    setPointIdwgIds(pointIdwgIds) {
      set({pointIdwgIds});
    },
    setPointFlocIds(pointFlocIds) {
      set({pointFlocIds});
    },
    changeIdwgSelectionModalDisplay(idwgSelectionModalDisplayed) {
      set({idwgSelectionModalDisplayed});
    },
    setAssignModalOpened(assignModalOpened) {
      set({assignModalOpened});
    },
    changeDocumentSelectionModalDisplay(documentSelectionModalDisplayed) {
      set({documentSelectionModalDisplayed});
    },
    changeCmlSelectionModalDisplay(cmlSelectionModalDisplayed) {
      set({cmlSelectionModalDisplayed});
    },
    setFetchPictures(fetchPictures) {
      set({fetchPictures});
    },
    setPictures(pictures) {
      set({pictures});
    },
    setFetchEventFlocNotifications(fetchEventFlocNotifications) {
      set({fetchEventFlocNotifications});
    },
    setFetchEventNotifications(fetchEventNotifications) {
      set({fetchEventNotifications});
    },
    setEventNotifications(eventNotifications) {
      set({eventNotifications});
    },
    setEventFlocNotifications(eventFlocNotifications) {
      set({eventFlocNotifications});
    },
    changeEventFlocNotificationFilter(eventFlocNotificationFilter) {
      set({eventFlocNotificationFilter});
    },
    setEventTasks(eventTasks) {
      set({eventTasks});
    },
    setFetchEventTasks(fetchEventTasks) {
      set({fetchEventTasks});
    },
    setFetchEventAttachments(fetchEventAttachments) {
      set({fetchEventAttachments});
    },
    setInspectionPointToDelete(inspectionPointToDelete?: Partial<EventInspectionPoint>) {
      set({inspectionPointToDelete});
    },
    setAttachmentToDelete(attachmentToDelete) {
      set({attachmentToDelete});
    },
    setCreateEventWorkflowFunc(createEventWorkflowFunc) {
      set({createEventWorkflowFunc});
    },
    changeWorkflowModalDisplay(eventWorkflowModalDisplayed) {
      set({eventWorkflowModalDisplayed});
    },
    setFetchEventFlocs(fetchEventFlocs) {
      set({fetchEventFlocs});
    },
    setEventFlocIds(eventFlocIds) {
      set({eventFlocIds});
    },
    setFilteredEventDocuments(filteredEventDocuments) {
      set({filteredEventDocuments});
    },
    changeEventFlocDocumentFilter(eventFlocDocumentFilter) {
      set({eventFlocDocumentFilter});
    },
    setFilteredEventFlocDocments(filteredEventFlocDocuments) {
      set({filteredEventFlocDocuments});
    },
    setEventFlocDocuments(eventFlocDocuments) {
      set({eventFlocDocuments});
    },
    setFetchEventFlocDocuments(fetchEventFlocDocuments) {
      set({fetchEventFlocDocuments});
    },
    setFetchEventDocuments(fetchEventDocuments) {
      set({fetchEventDocuments});
    },
    setEventFlocs(eventFlocs) {
      set({eventFlocs});
    },
    setFetchEventFlocCmls(fetchEventFlocCmls) {
      set({fetchEventFlocCmls});
    },
    setFetchEventFlocDamages(fetchEventFlocDamages) {
      set({fetchEventFlocDamages});
    },
    setFetchEventFlocInspectionPoints(fetchEventFlocInspectionPoints) {
      set({fetchEventFlocInspectionPoints});
    },
    setFilteredEventFlocCmls(filteredEventFlocCmls) {
      set({filteredEventFlocCmls});
    },
    setFilteredEventFlocDamages(filteredEventFlocDamages) {
      set({filteredEventFlocDamages});
    },
    changeEventFlocInspectionPointFilter(eventFlocInspectionPointFilter) {
      set({eventFlocInspectionPointFilter});
    },
    changeEventFlocDamageFilter(eventFlocDamageFilter) {
      set({eventFlocDamageFilter});
    },
    changeEventFlocCmlFilter(eventFlocCmlFilter) {
      set({eventFlocCmlFilter});
    },
    setEventFlocInspectionPoints(eventFlocInspectionPoints) {
      set({eventFlocInspectionPoints});
    },
    setEventFlocDamages(eventFlocDamages) {
      set({eventFlocDamages});
    },
    setEventFlocCmls(eventFlocCmls) {
      set({eventFlocCmls});
    },
    setEvents(events?: Partial<Event>[]) {
      set({events});
    },
    setFetchEvents(fetchEvents) {
      set({fetchEvents});
    },
    setCmlActiverFilter(cmlActiveFilter) {
      set({cmlActiveFilter});
    },
    setCmlSearchInput(cmlSearchInput) {
      set({cmlSearchInput});
    },
    setFilteredEventDamages(filteredEventDamages) {
      set({filteredEventDamages});
    },
    setFilteredEventCmls(filteredEventCmls) {
      set({filteredEventCmls});
    },
    setDocumentToDelete(documentToDelete) {
      set({documentToDelete});
    },
    setEventDocuments(eventDocuments) {
      eventDocuments?.sort((a, b) => {
        // Sort on document name
        if (a.document?.document && b.document?.document) {
          return a.document.document.localeCompare(b.document.document);
        }

        return 0;
      });
      set({eventDocuments});
    },
    setFetchEventCmls(fetchEventCmls) {
      set({fetchEventCmls});
    },
    setFetchEventDamages(fetchEventDamages) {
      set({fetchEventDamages});
    },
    setFetchEventInspectionPoints(fetchEventInspectionPoints) {
      set({fetchEventInspectionPoints});
    },
    prevSelectedDrawing() {
      const {selectedDrawing, eventInspectionDrawings} = get() ?? {};
      if (selectedDrawing && eventInspectionDrawings?.length) {
        const currentIndex = eventInspectionDrawings.findIndex((item: Partial<EventInspectionDrawing>) => item.inspectionDrawing?.id === selectedDrawing.id);
        if (currentIndex >= 0) {
          if (currentIndex !== 0) {
            set({selectedDrawing: eventInspectionDrawings[currentIndex - 1].inspectionDrawing});
          } else {
            set({selectedDrawing: eventInspectionDrawings[eventInspectionDrawings.length - 1].inspectionDrawing});
          }
        }
      }
    },
    nextSelectedDrawing() {
      const {selectedDrawing, eventInspectionDrawings} = get() ?? {};
      if (selectedDrawing && eventInspectionDrawings?.length) {
        const currentIndex = eventInspectionDrawings.findIndex((item: Partial<EventInspectionDrawing>) => item.inspectionDrawing?.id === selectedDrawing.id);
        if (currentIndex >= 0) {
          if (currentIndex === eventInspectionDrawings.length - 1) {
            set({selectedDrawing: eventInspectionDrawings[0].inspectionDrawing});
          } else {
            set({selectedDrawing: eventInspectionDrawings[currentIndex + 1].inspectionDrawing});
          }
        }
      }
    },
    setModalHidden(modalHidden) {
      set({modalHidden});
    },
    setFetchEventInspectionDrawings(fetchEventInspectionDrawings) {
      set({fetchEventInspectionDrawings});
    },
    setInspectionDrawingSortChangeCallback(inspectionDrawingSortChangeCallback) {
      set({inspectionDrawingSortChangeCallback});
    },
    setEventDamages: (eventDamages: Partial<EventDamage>[]) => set({eventDamages}),
    setEventInspectionPoints: (eventInspectionPoints: Partial<EventInspectionPoint>[]) => set({eventInspectionPoints}),
    setEventCmls(eventCmls) {
      set({eventCmls});
    },
    setEventInspectionDrawings(eventInspectionDrawings) {
      set({eventInspectionDrawings});
    },
    setSyncEventFromDB(syncEventFromDB) {
      set({syncEventFromDB});
    },
    setNewTaskData(newTaskData) {
      set({newTaskData});
    },
    setTaskToDelete: (taskToDelete?: Partial<EventTask>) => set({
      taskToDelete,
    }),
    setFlocToDelete: (flocToDelete?: Partial<EventFloc>) => set({
      flocToDelete,
    }),
    setLeftSideTabSelectedValue(leftSideTabSelectedValue) {
      set({leftSideTabSelectedValue});
    },
    canShowGenerateWorkpackBtn() {
      const {activeEvent} = get();
      return !!activeEvent && activeEvent.status === EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    },
    canShowValidateWorkpackBtn() {
      const {activeEvent, canShowGenerateWorkpackBtn} = get();
      return !!activeEvent
        && !!activeEvent.status
        && activeEvent.status !== EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET
        && activeEvent.status !== EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET
        && !!getNextEventStatus(activeEvent.status!) && !canShowGenerateWorkpackBtn();
    },
    canShowRejectWorkpackBtn() {
      const {activeEvent} = get();
      return !!activeEvent && !!activeEvent.status && !!getPrevEventStatus(activeEvent.status!)
        && activeEvent.status !== EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET;
    },
    getGenerateWorkpackBtnDisabledError() {
      const state = get();
      if (!state.eventFlocs?.length) {
        return EGenerateWorkpackBtnError.FLOC_LIST_EMPTY;
      }

      if (!state.eventTasks?.length || !(state.eventTasks && state.eventTasks.find((item: Partial<EventTask>) => item.task?.category === EWORKORDER_REF_EVENT_TASK_CATEGORY.INSPECTION))) {
        return EGenerateWorkpackBtnError.INSPECTION_TASK_LIST_EMPTY;
      }

      // if (state.activeEvent?.eventFlocs) {
      //   const hasFlocWithIntegrityAndNoDrawing = state.activeEvent?.eventFlocs.some((item: EventFloc) => item.functionalLocation.integrityStatus && !item.functionalLocation._count?.idwgFlocs);
      //   if (hasFlocWithIntegrityAndNoDrawing) {
      //     return EGenerateWorkpackBtnError.FLOC_INTS_NO_INSPECTION_DRAWING;
      //   }
      // }

      return null;
    },
    setLeftSideWidth(leftSideWidth?: number) {
      set({leftSideWidth});
    },
    setActiveEvent(activeEvent) {
      set({activeEvent, editEvent: activeEvent ? {...activeEvent} : undefined, updateData: activeEvent?.id === OBJ_NEW_ID ? {status: activeEvent.status, releasedDate: activeEvent.releasedDate ?? null, schedulingTagId: activeEvent.schedulingTagId ?? undefined, event: activeEvent.event} as EventUncheckedCreateInput : {}});
    },
    setUpdateData(updateData: EventUncheckedCreateInput | EventUncheckedUpdateInput) {
      set({updateData});
    },
    setUpdateSchedulingTagData(updateSchedulingTagData: FunctionalLocationUncheckedUpdateInput) {
      set({updateSchedulingTagData});
    },
    isSaved() {
      const state = get();
      return !((state.updateData && Object.keys(state.updateData).length > 0) || (state.updateSchedulingTagData && Object.keys(state.updateSchedulingTagData).length > 0));
    },
    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get();
      console.log(state.updateData);
      if (!Object.keys(state.updateData ?? {}).includes(field) && !forceCheck) {
        return false;
      }

      if (state.fieldErrors?.[field] === true) {
        return true;
      }

      switch (field) {
        case 'event':
          const event = (state.editEvent?.event ?? '').trim();
          return event === '' || event.length > MAX_LENGTH_VALIDATORS.EVENT;
        case 'description':
          const description = (state.editEvent?.description ?? '').trim();
          return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '';
        case 'code':
          const code = (state.editEvent?.code ?? '').trim();
          return code.length > MAX_LENGTH_VALIDATORS.CODE;
        case 'batchNumber':
          const batchNumber = (state.editEvent?.batchNumber ?? '').trim();
          return batchNumber.length > MAX_LENGTH_VALIDATORS.BATCH_NUMBER;
        case 'notes':
          const notes = (state.editEvent?.notes ?? '').trim();
          return notes.length > MAX_LENGTH_VALIDATORS.NOTES;
        case 'longDescription':
          const longDescription = (state.editEvent?.longDescription ?? '').trim();
          return longDescription.length > MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION;
        case 'reportSummary':
          const reportSummary = (state.editEvent?.reportSummary ?? '').trim();
          return reportSummary.length > MAX_LENGTH_VALIDATORS.REPORT_SUMMARY;
        default:
          break;
      }

      return false;
    },
    hasError(excludindFields?: string[], fields?: string[]) {
      const state = get();

      return (fields ?? ['description', 'event', 'batchNumber', 'notes', 'longDescription', 'reportSummary']).some((field: string) => !excludindFields?.includes(field) && state.hasFieldError(field, true));
    },
    changeDeleteEventModalDisplay: (isOpen: boolean) => set({
      deleteEventModalOpen: isOpen,
    }),
    deleteEvent: () => set(_state => {
      const newState: Partial<State> = {};
      newState.deleteEventModalOpen = false;
      newState.activeEvent = undefined;
      newState.editEvent = undefined;

      return newState;
    }),
    cancelEditData: () => set({
      updateData: {},
      uploadFile: undefined,
      fieldErrors: {},
      schedulingTagFieldErrors: {},
      updateSchedulingTagData: {},
      editEvent: get().activeEvent ? {...get().activeEvent} : undefined,
    }),
    updateEvent: (event: Partial<Event>) => set(getEventUpdateState(get(), event)),
    updateFieldError: (field: string, value: boolean) => set(state => {
      state.fieldErrors = {
        ...state.fieldErrors,
        [field]: value,
      };
    }),
    setEditEvent(editEvent?: Partial<Event>) {
      set({editEvent});
    },
    updateDataField: (field: string, value: unknown) => set(state => {
      const {activeEvent} = get();
      if (field.endsWith('Date')) {
        if (value) {
          if (typeof value === 'string') {
            value = convertDateDefaultStrToDateObj(value as string);
          }
        } else {
          value = null;
        }
      }

      return {
        updateData: {
          ...state.updateData,
          [field]: activeEvent?.id === OBJ_NEW_ID ? value : {
            set: value,
          },
        },
        fieldErrors: {
          ...state.fieldErrors,
          [field]: false,
        },
      };
    }),
    updateSchedulingTagDataField: (field: string, value: unknown) => set(state => ({
      updateSchedulingTagData: {
        ...state.updateSchedulingTagData,
        [field]: {
          set: value,
        },
      },
      schedulingTagFieldErrors: {
        ...state.schedulingTagFieldErrors,
        [field]: false,
      },
    })),
    resetData: () => set({
      ...initialState,
    }),
    deleteEventFloc: (flocToDelete?: Partial<EventFloc>, event?: Partial<Event>) => set(state => {
      const relatedEvent: Partial<Event> | undefined = event ?? state.activeEvent;
      const deletedFloc: Partial<EventFloc> | undefined = {...(flocToDelete ?? state.flocToDelete)};
      const newState: Partial<State> = {};
      if (relatedEvent && deletedFloc) {
        if (deletedFloc.id === state.flocToDelete?.id) {
          newState.flocToDelete = undefined;
        }

        const eventFlocs = relatedEvent.eventFlocs?.filter((item: EventFloc) => item.id !== deletedFloc.id!);

        if (relatedEvent.id === state.activeEvent?.id) {
          Object.assign(newState, getEventUpdateState(get(), {
            eventFlocs,
            _count: {
              ...state.activeEvent!._count,
              eventFlocs: eventFlocs?.length ?? 0,
            } as EventCount,
          }));
        } else {
          relatedEvent.eventFlocs = eventFlocs;
        }
      }

      return newState;
    }),
    updateEventTask: (taskToUpdate: Partial<EventTask>, event?: Partial<Event>) => set(state => {
      const relatedEvent: Partial<Event> | undefined | null = event ?? state.activeEvent;
      const newState: Partial<State> = {};
      if (relatedEvent) {
        const relatedEventTaskIdx = relatedEvent?.eventTasks?.findIndex((item: Partial<EventTask>) => item.id! === taskToUpdate.id!) ?? -1;
        if (relatedEventTaskIdx >= 0) {
          const eventTasks = [...relatedEvent.eventTasks!];
          eventTasks[relatedEventTaskIdx] = {
            ...relatedEvent.eventTasks![relatedEventTaskIdx],
            ...taskToUpdate,
          };
          if (relatedEvent.id === state.activeEvent?.id) {
            Object.assign(newState, getEventUpdateState(get(), {
              eventTasks,
            }));
          } else {
            relatedEvent.eventTasks = eventTasks;
          }
        }
      }

      return newState;
    }),
    deleteEventTask: (taskToDelete?: Partial<EventTask>, event?: Partial<Event>) => set(state => {
      const relatedEvent: Partial<Event> | undefined | null = event ?? state.activeEvent;
      const deletedTask: Partial<EventTask> | undefined = {...(taskToDelete ?? state.taskToDelete)};
      const newState: Partial<State> = {};
      if (relatedEvent && deletedTask) {
        if (deletedTask.id === state.taskToDelete?.id) {
          newState.taskToDelete = undefined;
        }

        const eventTasks = relatedEvent.eventTasks?.filter((item: EventTask) => item.id !== deletedTask.id!);
        if (relatedEvent.id === state.activeEvent?.id) {
          Object.assign(newState, getEventUpdateState(get(), {
            eventTasks,
            _count: {
              ...state.activeEvent!._count,
              eventTasks: eventTasks?.length ?? 0,
            } as EventCount,
          }));
        } else {
          relatedEvent.eventTasks = eventTasks;
        }
      }

      return newState;
    }),
    setActionConfirmModalOpened: (opened, actionKey, callback, params) => set({
      actionConfirmModalOpened: opened,
      actionConfirmModalActionKey: actionKey,
      actionConfirmModalCallback: callback,
      actionConfirmModalParams: params,
    }),
    checkActiveEventReportingValidity: (t: TFunction) => set(state => {
      const warnings = <string[]>[];
      const errors = <string[]>[];

      if (!state.activeEvent?.description) {
        errors.push(t('message.error.eventDescriptionRequired'));
      }

      if (!state.activeEvent?.inspectionDate) {
        errors.push(t('message.error.eventInspectionDateRequired'));
      }

      if (!state.activeEvent?.reportNumber) {
        errors.push(t('message.error.eventReportNumberRequired'));
      }

      if (!state.activeEvent?.reportDescription) {
        errors.push(t('message.error.eventReportDescriptionRequired'));
      }

      if (
        state.activeEvent?.status === EWORKORDER_EVENT_STATUS.REP_APPROVING
        && state.eventIntegrityStatuses
          ?.find((item: Partial<IntegrityStatus>) => !item.condition || Number(item.condition) === 0)) {
        errors.push(t('message.error.integrityStatusNotDone'));
      }

      for (const insp of state.eventInspectionPoints ?? []) {
        const check = checkEventInspectionPointValidity(insp as EventInspectionPoint, state.eventTasks as EventTask[], t);
        errors.push(...check.errors);
        warnings.push(...check.warnings);
      }

      for (const cml of state.eventCmls ?? []) {
        const check = checkEventCmlValidity(cml as EventCml, state.eventTasks as EventTask[], t);
        errors.push(...check.errors);
        warnings.push(...check.warnings);
      }

      for (const damage of state.eventDamages ?? []) {
        const check = checkEventDamageValidity(damage as EventDamage, state.eventTasks as EventTask[], t);
        errors.push(...check.errors);
        warnings.push(...check.warnings);
      }

      for (const floc of state.eventFlocs ?? []) {
        const check = checkFlocValidity(floc.functionalLocation!, t);
        errors.push(...check.errors);
        warnings.push(...check.warnings);
      }

      if (state.activeEvent?.status === EWORKORDER_EVENT_STATUS.REP_APPROVING) {
        for (const notification of state.eventNotifications ?? []) {
          if (notification.status !== ENOTIFICATION_NOTIF_STATUS.APPR) {
            errors.push(t('message.error.notificationApprovalRequired'));
          }
        }
      }

      return {
        eventValidationErrors: errors,
        eventValidationWarnings: warnings,
      };
    }),
    checkActiveEventPreparationValidity: (t: TFunction) => set(state => {
      const warnings = <string[]>[];
      const errors = <string[]>[];

      if (state.activeEvent?.status !== EWORKORDER_EVENT_STATUS.PREP_PROCESSING && !state.eventInspectionDrawings?.length) {
        warnings.push(t('message.error.eventInspectionDrawingRequired'));
      }

      if (state.activeEvent?.status !== EWORKORDER_EVENT_STATUS.PREP_PROCESSING && !state.eventDocuments?.length) {
        warnings.push(t('message.error.eventDocumentRequired'));
      }

      if (state.activeEvent?.status !== EWORKORDER_EVENT_STATUS.PREP_PROCESSING && !state.eventInspectionPoints?.length) {
        warnings.push(t('message.error.eventInspectionPointRequired'));
      }

      if (!state.eventTasks?.filter(t => t.task?.hasReporting).length) {
        errors.push(t('message.error.eventTaskRequired'));
      }

      for (const floc of state.eventFlocs ?? []) {
        const check = checkFlocValidity(floc.functionalLocation!, t);
        errors.push(...check.errors);
        warnings.push(...check.warnings);
      }

      return {
        eventValidationErrors: errors,
        eventValidationWarnings: warnings,
      };
    }),
    resetActiveEventValidation: () => set({
      eventValidationErrors: [],
      eventValidationWarnings: [],
    }),

    setEventAttachments(attachments) {
      set({eventAttachments: attachments});
    },

    setEventIntegrityStatuses(integrityStatuses) {
      set({eventIntegrityStatuses: integrityStatuses});
    },
  })),
);

// export default useEventStore;

const checkFlocValidity = (floc: FunctionalLocation, t: TFunction) => {
  const errors: Array<string> = [];
  const warnings: Array<string> = [];

  if (!floc.idwgFlocs?.length) {
    warnings.push(t('message.error.flocDrawingRequired', {floc: floc.floc, interpolation: {escapeValue: false}}));
  }

  return {errors, warnings};
};

const checkTechniqueInTasks = (tasks: EventTask[], techniqueId?: number) => {
  if (!techniqueId) {
    return true;
  }

  for (const task of tasks) {
    if (task.task.techniqueId === techniqueId) {
      return true;
    }
  }

  return false;
};

export const checkEventInspectionPointValidity = (point: EventInspectionPoint, eventTasks: EventTask[], t: TFunction) => {
  const errors: Array<string> = [];
  const warnings: Array<string> = [];

  if (!point.qualReading) {
    errors.push(t('message.error.inspectionPointQualReadingRequired'));
  }

  if (!point.inspectionPoint?.description) {
    errors.push(t('message.error.inspectionPointDescriptionRequired'));
  }

  if (!point.inspectionPoint?.technique) {
    warnings.push(t('message.error.inspectionPointTechniqueRequired'));
  } else if (!checkTechniqueInTasks(eventTasks, point.inspectionPoint?.techniqueId as number)) {
    warnings.push(t('message.error.inspectionPointTechniqueNotInWrko'));
  }

  return {errors, warnings};
};

export const checkEventCmlValidity = (cml: EventCml, eventTasks: EventTask[], t: TFunction) => {
  const errors: Array<string> = [];
  const warnings: Array<string> = [];

  if (!cml.qualReading) {
    errors.push(t('message.error.cmlQualReadingRequired'));
  }

  if (!cml.cml?.description) {
    errors.push(t('message.error.cmlDescriptionRequired'));
  }

  if (!cml.cml?.technique) {
    warnings.push(t('message.error.cmlTechniqueRequired'));
  } else if (!checkTechniqueInTasks(eventTasks, cml.cml?.techniqueId as number)) {
    warnings.push(t('message.error.cmlTechniqueNotInWrko'));
  }

  return {errors, warnings};
};

export const checkEventDamageValidity = (damage: EventDamage, eventTasks: EventTask[], t: TFunction) => {
  const errors: Array<string> = [];
  const warnings: Array<string> = [];

  if (!damage.qualReading) {
    errors.push(t('message.error.damageQualReadingRequired'));
  }

  if (!damage.damage?.description) {
    errors.push(t('message.error.damageDescriptionRequired'));
  }

  if (!damage.damage?.technique) {
    warnings.push(t('message.error.damageTechniqueRequired'));
  } else if (!checkTechniqueInTasks(eventTasks, damage.damage?.techniqueId as number)) {
    warnings.push(t('message.error.damageTechniqueNotInWrko'));
  }

  return {errors, warnings};
};
