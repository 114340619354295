import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {EventCountByStatusSitePlannedDateResponse} from '@app/graphql/__types__/graphql';
import {useDataStore} from '@app/stores/data';
import {useEventPerformanceDashboardStore} from '@app/stores/event/performanceDashboard';
import {ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS} from '@app/utils/constants';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, LabelList, LegendProps} from 'recharts';

type TBarChartData = {
  site: string;
  counters: {
    [key: string]: number;
  };
}

export default function EventCounterBarChart() {
  const {filteredData, getStatusColor, setAvailableStatuses, availableStatuses, getStatusStage, classFilters, plannedDateFilters} = useEventPerformanceDashboardStore();
  const [chartData, setChartData] = useState<Array<TBarChartData>>();
  const {selectedSites} = useDataStore();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const onClickBarCell = (status: string, site: string) => {
    navigate(ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS, {
      state: {
        statuses: [status],
        sites: [site],
        classes: classFilters,
        plannedDateFilters,
      },
    });
  };

  const renderLegend = (props: unknown) => (
    <ul className='m-4'>
      {
        (props as unknown as LegendProps).payload?.map(entry => (
          <li key={`item-${entry.value}`} className='flex gap-2 items-center'><span className='w-3 h-3 inline-block' style={{background: entry.color ?? 'white'}}/> <span className='flex-1'>{entry.value}</span></li>
        ))
      }
    </ul>
  );

  useEffect(() => {
    if (typeof filteredData !== 'undefined') {
      const chartDataToBuild: Record<string, TBarChartData> = {};
      selectedSites?.forEach(site => {
        chartDataToBuild[site] = {
          site,
          counters: {},
        };
      });
      const evtStatuses: string[] = [];
      filteredData?.forEach((item: EventCountByStatusSitePlannedDateResponse) => {
        const {site, status, counter} = item;
        if (Object.keys(chartDataToBuild).includes(site)) {
          if (!chartDataToBuild[site].counters[status]) {
            chartDataToBuild[site].counters[status] = 0;
          }

          if (!evtStatuses.includes(status)) {
            evtStatuses.push(status);
          }

          chartDataToBuild[site].counters[status] += counter;
        }
      });
      setAvailableStatuses(_.sortBy(evtStatuses, [status => `${_.capitalize(getStatusStage(status))}: ${t(`label.eventStatuses.${status.toLowerCase()}`)}`]));
      setChartData(Object.values(chartDataToBuild));
    }
  }, [filteredData]);

  return (
    <SpinnerLoaderComponent isLoading={typeof chartData === 'undefined'}>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3'/>
          <XAxis dataKey='site'/>
          <YAxis/>
          <Tooltip/>
          <Legend verticalAlign='middle' align='right' width={200} layout='vertical' content={renderLegend}/>
          {availableStatuses?.map((status: string) => (
            <Bar key={status} isAnimationActive name={`${t(`label.eventStatuses.${status.toLowerCase()}`)}`} className='hover:cursor-pointer hover:opacity-85' stackId='site' fill={getStatusColor(status)} dataKey={`counters.${status}`} activeBar={{opacity: 0.9}} onClick={(props: unknown) => onClickBarCell(status, (props as unknown as {site: string}).site)}>
              <LabelList dataKey={`counters.${status}`} fill='white' position='center'/>
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </SpinnerLoaderComponent>
  );
}
