import {useLazyQuery} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {
  EFlagStatus,
  FunctionalLocation,
  GetInspectionPointsByFlocIdQuery,
  InspectionPoint,
} from '@app/graphql/__types__/graphql';
import {INSPECTION_POINTS_GET_BY_FLOC_ID} from '@app/graphql/requests';

import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {searchArray} from '@app/utils/functions';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuClipboardList, LuList, LuPlus} from 'react-icons/lu';
import InspectionPointListModal from '@app/components/Common/Block/InspectionPoint/InspectionPointBlock/InspectionPointListModal';
import _ from 'lodash';
import EventInspectionPointItem from '@app/components/Common/Block/InspectionPoint/InspectionPointItem';
import useInspectionPointStore from '@app/stores/insp';
import {OBJ_NEW_ID} from '@app/utils/constants';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';

type TInspectionPointList = Readonly<{
  floc: Partial<FunctionalLocation>;
  readonly?: boolean;
}>;

/** Floc inspection point list */
export default function InspectionPointList({floc, readonly}: TInspectionPointList) {
  const {t} = useTranslation();
  const prms = useUserPermissions();
  // const config = useOptimusConfig();
  // const {startLoading, stopLoading} = useLayoutStore();
  const {
    inspectionPoints: flocInspectionPoints,
    setInspectionPoints: setFlocInspectionPoints,
    setFetchInspectionPoints,
    fetchInspectionPoints,
  } = useFlocStore();
  const {setActiveInspectionPoint} = useInspectionPointStore();
  // const [allInspectionPoints, setAllInspectionPoints] = useState<InspectionPoint[]>([]);
  const [filteredInspectionPoints, setFilteredInspectionPoints] = useState<Partial<InspectionPoint>[]>();
  const [inspectionPointListModalDisplayed, changeInspectionPointListModalDisplay] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [getInspectionPointListApi] = useLazyQuery<GetInspectionPointsByFlocIdQuery>(INSPECTION_POINTS_GET_BY_FLOC_ID);

  useEffect(() => {
    setFetchInspectionPoints(getInspectionPoints);
  }, [floc.id]);

  useEffect(() => {
    fetchInspectionPoints?.();
  }, [fetchInspectionPoints]);

  useEffect(() => {
    const items = flocInspectionPoints ?? [];
    setFilteredInspectionPoints(
      searchInput !== '' ? searchArray(items, searchInput, undefined, ['__typename']) : items,
    );
  }, [flocInspectionPoints, searchInput]);

  const handleCreateNew = () => {
    setActiveInspectionPoint({
      id: OBJ_NEW_ID,
      flagStatus: EFlagStatus.Y,
      flocId: floc.id,
      functionalLocation: floc as FunctionalLocation,
      __typename: 'InspectionPoint',
    });
  };

  const getInspectionPoints = () => {
    if (floc.id) {
      getInspectionPointListApi({
        variables: {
          flocId: floc.id,
        },
        fetchPolicy: 'no-cache',
      })
        .then(queryResult => setFlocInspectionPoints(_.sortBy((queryResult.data?.inspectionPoints ?? []) as Partial<InspectionPoint>[], [item => item.position])));
    } else {
      setFlocInspectionPoints([]);
    }
  };

  return (
    <div>
      <FormGroupHeader
        menuItems={_.concat(prms.inspectionPoints.create ? [(
          <RadDropdownMenuItem
            key='new'
            onClick={handleCreateNew}
          >
            <LuPlus className='mr-2'/>

            {t('label.new')}
          </RadDropdownMenuItem>)] : [], [
          (<RadDropdownMenuItem
            key='display-list'
            onClick={() => changeInspectionPointListModalDisplay(true)}
          >
            <LuList className='mr-2'/>

            {t('label.displayList')}
          </RadDropdownMenuItem>
          ),
        ])}
        actions={
          <SearchBar
            className='w-[300px]'
            onChange={e => setSearchInput(e.target?.value)}
          />
        }
      >
        <div className='flex items-center'>
          <LuClipboardList size={20} className='mr-2'/>

          {t('label.checklist')}
        </div>
      </FormGroupHeader>

      <div className='flex flex-col w-full gap-1 mb-2 mt-2'>
        {filteredInspectionPoints?.map((item: Partial<InspectionPoint>) =>
          // const eventInspectionPoint = eventInspectionPoints?.find((evtInspectionPoint: Partial<EventInspectionPoint>) => evtInspectionPoint.inspId === item.id);
          (
            <EventInspectionPointItem
              key={item.id}
              hasMenuContext
              // readonly={readonly || config.getActionIsDisabled('event', 'pointSelect', floc.status)}
              readonly={readonly}
              inspectionPoint={item}
            />
          ),
        )}
      </div>
      {inspectionPointListModalDisplayed && <InspectionPointListModal open hasItems title={t('label.inspectionPointList')} description={`${t('label.functionalLocation').toUpperCase()} ${floc.floc}`} sortOrders={['asc']} items={flocInspectionPoints} onClose={() => changeInspectionPointListModalDisplay(false)}/>}
    </div>
  );
}
