import {IconType} from 'react-icons/lib';
import {ENOTIFICATION_NOTIF_STATUS, EWORKORDER_EVENT_STATUS} from '../enums';
import {LuRocket, LuRotateCcw, LuUndoDot} from 'react-icons/lu';

export enum WorkflowActionDirection {
  forward = 'FORWARD',
  backward = 'BACKWARD',
  secondary = 'SECONDARY',
}
export enum WorkflowConfirmType {
  confirm = 'CONFIRM',
  comment = 'COMMENT',
  reason = 'REASON',
}
export type WorkflowAction<keyType=string> = {
  key: keyType;
  dir: WorkflowActionDirection;
  from: string | null;
  to: string;
  confirm?: WorkflowConfirmType;
  closeForm?: boolean;
  icon?: IconType;
};

export type EventWorkflowActionKey = 'import_of_event' | 'release_of_event' | 'generation_of_workpack' | 'review_of_workpack' | 'second_review_of_workpack' | 'reject_review' | 'reject_workpack' | 'approve_workpack' | 'assign_workpack' | 'unassign_workpack' | 'back_to_preparation' | 'reassign_workpack' | 'report_workpack' | 'back_to_execution' | 'preparation_of_report' | 'reject_report_revi' | 'review_of_report' | 'reject_report_appr' | 'approve_report' | 'relaunch_event_export';
export const EVENT_WORKFLOW: WorkflowAction<EventWorkflowActionKey>[] = [
  {
    key: 'import_of_event',
    dir: WorkflowActionDirection.forward,
    from: null,
    to: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
  },
  {
    key: 'release_of_event',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PEND_WAITING_RELEASE,
    to: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
  },
  {
    key: 'generation_of_workpack',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    icon: LuRocket,
  },
  {
    key: 'review_of_workpack',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'second_review_of_workpack',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_ADDITIONAL_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'reject_review',
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.PREP_ADDITIONAL_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    confirm: WorkflowConfirmType.reason,
    closeForm: true,
  },
  {
    key: 'reject_workpack',
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    confirm: WorkflowConfirmType.reason,
    closeForm: true,
  },
  {
    key: 'approve_workpack',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.PREP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'assign_workpack',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN,
    to: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    closeForm: true,
    confirm: WorkflowConfirmType.comment,
  },
  {
    key: 'back_to_preparation',
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.PREP_REVIEWING,
    closeForm: true,
    icon: LuUndoDot,
  },
  {
    key: 'unassign_workpack',
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN,
    closeForm: true,
    confirm: WorkflowConfirmType.comment,
  },
  {
    key: 'reassign_workpack',
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    confirm: WorkflowConfirmType.comment,
    icon: LuRotateCcw,
  },
  {
    key: 'report_workpack',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    to: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    closeForm: true,
  },
  {
    key: 'back_to_execution',
    dir: WorkflowActionDirection.secondary,
    from: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    to: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'preparation_of_report',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    to: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'reject_report_revi',
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.REP_PROCESSING,
    confirm: WorkflowConfirmType.reason,
  },
  {
    key: 'review_of_report',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    to: EWORKORDER_EVENT_STATUS.REP_APPROVING,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'reject_report_appr',
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.REP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.REP_REVIEWING,
    confirm: WorkflowConfirmType.reason,
  },
  {
    key: 'approve_report',
    dir: WorkflowActionDirection.forward,
    from: EWORKORDER_EVENT_STATUS.REP_APPROVING,
    to: EWORKORDER_EVENT_STATUS.WACK,
    confirm: WorkflowConfirmType.comment,
    closeForm: true,
  },
  {
    key: 'relaunch_event_export',
    dir: WorkflowActionDirection.backward,
    from: EWORKORDER_EVENT_STATUS.EXER,
    to: EWORKORDER_EVENT_STATUS.WACK,
    closeForm: true,
  },
];

export type IanWorkflowActionKey = 'cration_of_ian' | 'review_of_ian' | 'approve_of_ian' | 'relaunch_ian_export';
export const IAN_WORKFLOW: WorkflowAction<IanWorkflowActionKey>[] = [
  {
    key: 'cration_of_ian',
    dir: WorkflowActionDirection.forward,
    from: null,
    to: ENOTIFICATION_NOTIF_STATUS.INIT,
  },
  {
    key: 'review_of_ian',
    dir: WorkflowActionDirection.forward,
    from: ENOTIFICATION_NOTIF_STATUS.INIT,
    to: ENOTIFICATION_NOTIF_STATUS.WAPP,
    closeForm: true,
  },
  {
    key: 'approve_of_ian',
    dir: WorkflowActionDirection.forward,
    from: ENOTIFICATION_NOTIF_STATUS.WAPP,
    to: ENOTIFICATION_NOTIF_STATUS.WACK,
    closeForm: true,
  },
  {
    key: 'relaunch_ian_export',
    dir: WorkflowActionDirection.backward,
    from: ENOTIFICATION_NOTIF_STATUS.EXER,
    to: ENOTIFICATION_NOTIF_STATUS.WACK,
    closeForm: true,
  },
];

export const workflows = {
  event: EVENT_WORKFLOW,
  ian: IAN_WORKFLOW,
};

export const getWorkflowAvailableActions = (wfType: keyof typeof workflows, currentStatus?: string | null) : {
  forward: WorkflowAction | undefined;
  backward: WorkflowAction | undefined;
  secondary: WorkflowAction[];
} => {
  const actions
    = workflows[wfType]
      .filter(action => action.from === currentStatus)
      .map(action => ({...action, key: action.key as EventWorkflowActionKey, closeForm: !!action.closeForm}));

  return {
    forward: actions.find(action => action.dir === WorkflowActionDirection.forward),
    backward: actions.find(action => action.dir === WorkflowActionDirection.backward),
    secondary: actions.filter(action => action.dir === WorkflowActionDirection.secondary),
  };
};

export const getNextEventStatus = (currentStatus?: EWORKORDER_EVENT_STATUS | null | string, choice?: number): EWORKORDER_EVENT_STATUS | null => {
  switch (currentStatus) {
    case EWORKORDER_EVENT_STATUS.PEND_WAITING_RELEASE:
      return EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    // case EWORKORDER_EVENT_STATUS.PREP_PENDING:
    //   return EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.PREP_PROCESSING:
      return EWORKORDER_EVENT_STATUS.PREP_REVIEWING;
    case EWORKORDER_EVENT_STATUS.PREP_REVIEWING:
      return EWORKORDER_EVENT_STATUS.PREP_APPROVING;
    case EWORKORDER_EVENT_STATUS.PREP_APPROVING:
      return EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN;
    case EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN:
      return EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION;
    case EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION:
      // Export to tablet
      if (choice === 1) {
        return EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET;
      }

      return EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION;
    case EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET:
      return EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET;
    case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET:
      // Inspection not completed
      if (choice === 1) {
        return EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET;
      }

      return EWORKORDER_EVENT_STATUS.REP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION:
      return EWORKORDER_EVENT_STATUS.REP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.REP_PROCESSING:
      return EWORKORDER_EVENT_STATUS.REP_REVIEWING;
    case EWORKORDER_EVENT_STATUS.REP_REVIEWING:
      return EWORKORDER_EVENT_STATUS.REP_APPROVING;
    case EWORKORDER_EVENT_STATUS.REP_APPROVING:
      return EWORKORDER_EVENT_STATUS.WACK;
    default:
      break;
  }

  return null;
};

export const getPrevEventStatus = (currentStatus?: EWORKORDER_EVENT_STATUS | null | string): EWORKORDER_EVENT_STATUS | null => {
  switch (currentStatus) {
    case EWORKORDER_EVENT_STATUS.PREP_REVIEWING:
    case EWORKORDER_EVENT_STATUS.PREP_APPROVING:
      return EWORKORDER_EVENT_STATUS.PREP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION:
    case EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET:
      return EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN;
    case EWORKORDER_EVENT_STATUS.REP_APPROVING:
    case EWORKORDER_EVENT_STATUS.REP_REVIEWING:
      return EWORKORDER_EVENT_STATUS.REP_PROCESSING;
    case EWORKORDER_EVENT_STATUS.EXEC_INSPECTION_EXECUTION_TABLET:
      return EWORKORDER_EVENT_STATUS.EXEC_AWAITING_EXPORT_TABLET;
    default:
      break;
  }

  return null;
};
