import React, {MouseEvent, useEffect, useState} from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {Damage, Event, EventDamage, FunctionalLocation, GetEventDamageByEvtDmgIdQuery, GetLatestEventDamageByDmgIdQuery, GetLatestEventDamageWithQualReadByDmgIdQuery, GetLatestPictureByQuery, InspectionDrawing, Notification, NotificationDamage, Picture, PictureWhereInput, RefDamageValCode, UpdateDamageByIdMutation} from '@app/graphql/__types__/graphql';
import {DAMAGES_UPDATE_BY_ID, PICTURES_GET_LATEST_BY, WORKORDER_EVENT_DAMAGES_GET_BY_EVT_DMG_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID, WORKORDER_EVENT_DAMAGES_GET_LATEST_WITH_QUALREAD_BY_DMG_ID} from '@app/graphql/requests';
import {useTranslation} from 'react-i18next';
import ItemCard, {ItemCardContent} from '../../ItemCard';
import {LuBell, LuExternalLink, LuMapPin, LuPlusSquare} from 'react-icons/lu';
import {FaRegImage} from 'react-icons/fa';
import ClassPositionContainer from '../../ItemCard/ClassPositionContainer';
import MeasurementValueContainer from '../../ItemCard/MeasurementValueContainer';
import {RadContextMenu, RadContextMenuTrigger, RadContextMenuContent, RadContextMenuItem, RadButton, RadBadge} from '@holis/react-ui/rad';
import useDamageStore from '@app/stores/damage';
import useNotificationStore from '@app/stores/notification';
import {APP_AUTO_NAMING, OBJ_NEW_ID, QUALREADING_COLOR} from '@app/utils/constants';
import {ENOTIFICATION_NOTIF_STATUS} from '@app/utils/enums';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import PictureCard from '../../Picture/Block/PictureCard';
import {useEventStore} from '@app/stores/event';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';

type TDamageCard = Readonly<{
  dmgObject: Partial<EventDamage | Damage | NotificationDamage>;
  event?: Partial<Event>;
  objectItem?: Partial<Event | Notification | InspectionDrawing | FunctionalLocation>;
  className?: string;
  hasMenuContext?: boolean;
  display2dDisabled?: boolean;
  hideAddNotifBtn?: boolean;
  removeItemTitle?: string;
  readonly?: boolean;
  handleRemoveDamage?: () => void;
  onDisplay2dChanged?: (newValue?: boolean | null) => void;
  drawingStoreId?: string;
}>

export default function DamageCard({dmgObject, objectItem, className, hasMenuContext, event, hideAddNotifBtn, onDisplay2dChanged, drawingStoreId, display2dDisabled, readonly}: TDamageCard) {
  const prms = useUserPermissions();
  const canAddIAN = prms.notifications.create;
  const [damageEvent, setDamageEvent] = useState<Partial<Event> | null>();
  const [qualReading, setQualReading] = useState<Partial<RefDamageValCode> | null>();
  const [damage, setDamage] = useState<Partial<Damage>>();
  const [display2d, setDisplay2d] = useState<boolean>(damage?.display2d !== false);
  const [evtDamage, setEvtDamage] = useState<Partial<EventDamage> | null>();
  const {setActiveDamage} = useDamageStore();
  const notifState = useNotificationStore();
  const eventState = useEventStore();
  const {setActiveNotification, activeNotification, addEventDamage} = notifState;
  const {lastDamageUpdated, lastDamageUpdatedAt, lastDamagePicUpdatedAt, lastDamagePicUpdated} = objectItem?.__typename === 'Notification' ? notifState : eventState;
  const [latestPic, setLatestPic] = useState<Picture | null>();
  const [evtDamageApiCalled, setEvtDamageApiCalled] = useState<boolean>(false);
  const [getEventDamageByEvtDmgIdApi] = useLazyQuery<GetEventDamageByEvtDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_BY_EVT_DMG_ID);
  const [getLatestPictureApi] = useLazyQuery<GetLatestPictureByQuery>(PICTURES_GET_LATEST_BY);
  const [getLatestEventDamageByDmgIdApi] = useLazyQuery<GetLatestEventDamageByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID);
  const {startLoading, stopLoading} = useLayoutStore();
  const {t} = useTranslation();
  const [updateDamageByIdApi] = useMutation<UpdateDamageByIdMutation>(DAMAGES_UPDATE_BY_ID);
  const {changeDamageDisplay2dUpdated} = useIdwgStore(drawingStoreId ?? `${IDWG_PREFIX}${damage?.idwgId}`);
  const [getLatestEventDamageWithQualReadByDmgIdApi] = useLazyQuery<GetLatestEventDamageWithQualReadByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_WITH_QUALREAD_BY_DMG_ID);

  const handleChangeDisplay2d = (e: MouseEvent) => {
    e.stopPropagation();
    startLoading();
    updateDamageByIdApi({
      variables: {
        id: damage?.id!,
        data: {
          display2d: {
            set: !display2d,
          },
        },
      },
    }).then(result => {
      const newDamage = result.data?.updateOneDamage as Partial<Damage>;
      const displayed2d = !!newDamage?.display2d;
      setDisplay2d(displayed2d);
      onDisplay2dChanged?.(displayed2d);
      changeDamageDisplay2dUpdated(newDamage);
    }).catch(_error => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  const getEventDamage = () => {
    getEventDamageByEvtDmgIdApi({
      variables: {
        evtId: damageEvent!.id!,
        dmgId: damage!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      setEvtDamage({...result.data?.eventDamage} as (Partial<EventDamage> | undefined));
    });
  };

  const getLatestEventDamage = () => {
    getLatestEventDamageByDmgIdApi({
      variables: {
        dmgId: damage!.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      setEvtDamage(result.data?.findFirstEventDamage as (Partial<EventDamage> | undefined));
    });
  };

  const getLatestPic = () => {
    const data: PictureWhereInput = {
      dmgeId: {equals: damage!.id!},
    };

    if (objectItem?.__typename !== 'Notification' && damageEvent?.id) {
      data.wrkoId = {
        equals: damageEvent?.id,
      };
    }

    return getLatestPictureApi({
      variables: {
        data,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => {
      if ((queryResult.data?.findFirstPicture as Picture)?.id) {
        setLatestPic(queryResult.data?.findFirstPicture as (Picture | null));
      }
    });
  };

  const getEventDamageToShow = () => {
    if (damageEvent) {
      getEventDamage();
    } else {
      getLatestEventDamage();
    }
  };

  const createNotif = () => {
    setActiveNotification({
      id: OBJ_NEW_ID,
      flocId: damage!.flocId,
      functionalLocation: damage!.functionalLocation as FunctionalLocation,
      wrkoId: damageEvent?.id,
      event: damageEvent as (Event | undefined),
      status: ENOTIFICATION_NOTIF_STATUS.INIT,
      notif: APP_AUTO_NAMING,
      creationDate: new Date(),
      __typename: 'Notification',
    }, damage);
  };

  const getInitData = () => {
    if (!evtDamage) {
      setEvtDamageApiCalled(true);
      getEventDamageToShow();
    }

    getLatestPic();
  };

  useEffect(() => {
    if (damage?.id && typeof damageEvent !== 'undefined' && !evtDamageApiCalled) {
      getInitData();
    }
  }, [damage?.id, damageEvent]);

  useEffect(() => {
    if (damage?.id && lastDamageUpdated?.id === damage?.id) {
      getEventDamageToShow();
      setDamage(lastDamageUpdated);
    }
  }, [lastDamageUpdatedAt, lastDamageUpdated]);

  useEffect(() => {
    if (damage?.id && lastDamagePicUpdated?.id === damage?.id) {
      getLatestPic();
    }
  }, [lastDamagePicUpdatedAt, lastDamagePicUpdated]);

  useEffect(() => {
    if (evtDamage?.id && activeNotification?.id) {
      addEventDamage(evtDamage, false);
    }
  }, [evtDamage, activeNotification?.id]);

  useEffect(() => {
    if (dmgObject.__typename !== 'NotificationDamage') {
      setQualReading(evtDamage?.qualReading ?? null);
    }
  }, [evtDamage]);

  useEffect(() => {
    if (damage?.id && evtDamage?.id && (!evtDamage?.qualReading || evtDamage.qualReading.codeCond === '00') && objectItem?.__typename !== 'Event' && objectItem?.__typename !== 'Notification' && dmgObject.__typename !== 'NotificationDamage' && dmgObject.__typename !== 'EventDamage') {
      getLatestEventDamageWithQualReadByDmgIdApi({
        variables: {
          dmgId: damage!.id,
          id: evtDamage!.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        if (queryResult.data?.findFirstEventDamage) {
          setQualReading((queryResult.data?.findFirstEventDamage as EventDamage).qualReading ?? null);
        }
      });
    } else if (dmgObject.__typename !== 'NotificationDamage' && typeof evtDamage !== 'undefined') {
      setQualReading(evtDamage?.qualReading ?? null);
    }
  }, [damage?.id, evtDamage]);

  useEffect(() => {
    if (dmgObject.__typename === 'EventDamage') {
      setDamage((dmgObject as Partial<EventDamage>).damage);
      setDamageEvent(event ?? (dmgObject as Partial<EventDamage>).event ?? null);
      setEvtDamage(dmgObject as Partial<EventDamage>);
    } else if (dmgObject.__typename === 'NotificationDamage') {
      setDamage((dmgObject as Partial<NotificationDamage>).damage);
      setDamageEvent(event ?? null);
      setQualReading(dmgObject?.qualReading ?? null);
      setEvtDamage(null);
    } else {
      setDamageEvent(event ?? null);
      setDamage(dmgObject as Partial<Damage>);
      setEvtDamage(['FunctionalLocation', 'InspectionDrawing'].includes(objectItem?.__typename ?? '') ? evtDamage : null);
    }
  }, [dmgObject, event, evtDamage]);
  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <ItemCard className={className} onClick={() => setActiveDamage(damage!)}>

          <ItemCardContent className='w-full flex items-center gap-2 text-sm'>

            <ClassPositionContainer class={damage?.codeGroup?.class.class ?? '-'} position={damage?.position}/>

            <div className='flex-1 flex flex-col py-1 gap-1 self-stretch'>
              <div className='flex gap-1 items-center'>
                {typeof display2d === 'boolean' && <div>
                  <RadButton size='icon' variant='ghost' className='h-8 w-8' onClick={!display2dDisabled ? handleChangeDisplay2d : undefined}><LuMapPin size={16} className={display2d ? 'text-primary' : 'text-gray-400'}/></RadButton>
                </div>}
                <div className='font-semibold line-clamp-2'>{damage?.description}</div>
              </div>
              <div className='flex-1 line-clamp-3'>
                {typeof damage?.longDescription === 'string' && damage?.longDescription !== '' && <div className='whitespace-pre-wrap'>{damage?.longDescription}</div>}
                {typeof evtDamage?.notes === 'string' && evtDamage?.notes !== '' && <div className='whitespace-pre-wrap'>{evtDamage?.notes}</div>}
              </div>
              <div className='flex gap-1 items-center text-xs mt-1 mb-1'>
                <RadBadge className='bg-gray-400 hover:bg-gray-400 py-0'>{damage?.technique?.technique ?? '-'}</RadBadge>
                <div>&nbsp; | &nbsp;</div>
                <span>{damage?.functionalLocation?.floc ?? '-'}</span>
                {
                  !!damage?.notificationDamages?.length && (
                    <>
                      <div>&nbsp; | &nbsp;</div>
                      <div className='text-primary font-semibold flex gap-1 items-center'>
                        <div className='relative w-[20px] mr-2'>
                          <LuBell size={16}/>
                          <div className='absolute rounded-full bottom-[-8px] right-[-7px] bg-gray-100 text-center aspect-square w-4 h-4 text-[10px] text-black'>{damage?.notificationDamages?.length}</div>
                        </div>
                        <div className='font-semibold'>{damage?.notificationDamages[0].notification?.notif}</div>
                      </div>
                    </>
                  )
                }
              </div>
            </div>

            <div className='w-[116px] self-stretch rounded-md bg-gray-100'>
              {/* {latestPicSrc ? <img className='w-full h-full object-cover rounded-md' src={`${latestPicSrc}`} alt='dmg-pic'/> : ( */}
              {latestPic ? <PictureCard showOnlyPicture className='h-full' pictureClassName='rounded-md w-full h-full' picture={latestPic}/> : (
                <div className='h-full flex flex-col justify-center items-center'>
                  <FaRegImage size={20}/>
                  <span className='text-xs '>{t('label.noPicture')}</span>
                </div>
              )}
            </div>

            <MeasurementValueContainer className='w-14' measurementValue={qualReading?.valCode} measurementColor={(qualReading?.integrityCondition?.color ?? QUALREADING_COLOR) as string}/>
          </ItemCardContent>

        </ItemCard>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveDamage(damage!)}>
          <LuExternalLink className='mr-2'/> {t('label.open')}
        </RadContextMenuItem>
        {(!hideAddNotifBtn && canAddIAN) && <RadContextMenuItem disabled={readonly} onClick={createNotif}>
          <LuPlusSquare className='mr-2'/> {t('label.addIAN')}
        </RadContextMenuItem>}
        {/* {!!handleRemoveDamage && <RadContextMenuItem disabled={readonly} className='item-destructive' onClick={handleRemoveDamage}>
          <LuTrash2 className='mr-2'/> {removeItemTitle ?? (t(dmgObject.__typename === 'NotificationDamage' ? 'label.removeFromNotification' : 'label.removeFromEvent'))}
        </RadContextMenuItem>} */}
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
