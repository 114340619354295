import {QueryResult, useMutation, useQuery} from '@apollo/client';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {CreateIdwgTechniquesMutation, DeleteIdwgTechniquesByIdwgIdAndTechniqueIdsMutation, GetAllRefDwgTypesQuery, GetAllRefEventTechniquesQuery, GetAllSectorsQuery, GetTechniquesByIdwgIdQuery, InspectionDrawing, RefDwgType, RefEventTechnique, Sector, UpdateInspectionDrawingByIdMutation} from '@app/graphql/__types__/graphql';
import {DWG_IDWG_TECHNIQUE_CREATE_MANY, DWG_IDWG_TECHNIQUE_DELETE_MANY_BY_IDWG_ID_AND_TECHNIQUE_IDS, DWG_REF_TYPES_GET_MANY, INSPECTION_DRAWINGS_UPDATE_BY_ID, SECTORS_GET_ALL, WORKORDER_REF_EVENT_TECHNIQUES_GET_BY_IDWG_ID, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import useIdwgStore, {IDWG_PREFIX, MAX_LENGTH_VALIDATORS, ZOD_IDWG_DATAS} from '@app/stores/idwg';
import {useLayoutStore} from '@app/stores/layout';
import {TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps} from '@app/types/app';
import {EFieldType} from '@app/utils/enums';
import {setObjValueByPath} from '@app/utils/functions';
import {handleFormInputKeydown, renderCodeAndDescription} from '@app/utils/functions/forms';
import {Option, RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, {InputHTMLAttributes, RefObject, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {LuClipboardList} from 'react-icons/lu';
import {z} from 'zod';
import {useNavigate} from 'react-router-dom';
import {ROUTE_DOCUMENTATION_IDWG_DETAIL} from '@app/utils/constants';

type TIdwgInfo = Readonly<{
  idwg: Partial<InspectionDrawing>;
}>;

export default function IdwgInfo({idwg}: TIdwgInfo) {
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const navigate = useNavigate();
  const {
    editIdwg,
    setEditIdwg,
    updateData,
    setUpdateData,
    updateDataField,
    updateInspectionDrawing,
    fetchInspectionDrawings,
    updateFieldError,
    hasFieldError,
    setTechniques,
    techniques,
    activeInspectionDrawing,
  } = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const [submitRequested, setSubmitRequested] = useState<boolean>(false);

  const [updateIdwgByIdApi] = useMutation<UpdateInspectionDrawingByIdMutation>(INSPECTION_DRAWINGS_UPDATE_BY_ID);
  const idwgTechniquesResult = useQuery<GetTechniquesByIdwgIdQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_BY_IDWG_ID, {
    variables: {
      idwgId: idwg.id,
    },
    fetchPolicy: 'no-cache',
  });
  const dwgTypesQueryResult = useQuery<GetAllRefDwgTypesQuery>(DWG_REF_TYPES_GET_MANY);
  const sectorsQueryResult = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL);
  const techniquesResult = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY);
  const [addIdwgTechniquesApi] = useMutation<CreateIdwgTechniquesMutation>(DWG_IDWG_TECHNIQUE_CREATE_MANY);
  const [deleteIdwgTechniquesApi] = useMutation<DeleteIdwgTechniquesByIdwgIdAndTechniqueIdsMutation>(DWG_IDWG_TECHNIQUE_DELETE_MANY_BY_IDWG_ID_AND_TECHNIQUE_IDS);
  const addIdwgTechniqueItems = async (techniqueIds: number[]) => addIdwgTechniquesApi({
    variables: {
      data: techniqueIds.map((techniqueId: number) => ({
        idwgId: idwg.id,
        techniqueId,
      })),
    },
  });

  const deleteIdwgTechniqueItems = async (techniqueIds: number[]) => deleteIdwgTechniquesApi({
    variables: {
      techniqueIds,
      idwgId: idwg.id!,
    },
  });
  const zodFormObject = z.object(_.omit(ZOD_IDWG_DATAS(t), 'flocId', 'typeId'));
  const form = useForm<z.infer<typeof zodFormObject>>({
    resolver: zodResolver(zodFormObject),
    values: {
      idwg: idwg?.idwg?.toString() ?? '',
      revision: idwg?.revision?.toString() ?? '',
      description: idwg?.description?.toString() ?? '',
      sectorId: idwg?.sectorId ?? 0,
      docName: idwg?.docName?.toString() ?? '',
    },
    mode: 'onBlur',
  });

  const htmlForm = useRef<HTMLFormElement | null>(null);

  const LEFT_PANEL_TABS = [
    'general',
    'floc',
    'grid',
    'cml',
    'damage',
  ];
  const anchRef: Partial<{ [K in TPanelKey]: RefObject<HTMLDivElement> }> = {};

  for (const k of LEFT_PANEL_TABS) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    anchRef[k] = useRef(null);
  }

  type TPanelKey = typeof LEFT_PANEL_TABS[number];

  const _onValidateTechniqueSelection = async (newValue: Option[]) => {
    const currentTechniqueIds: number[] = techniques?.map(item => item.id!) ?? [];
    const selectedTechniqueIds: number[] = newValue.map(option => parseInt(option.value, 10));
    const oldSelectedIds = _.difference(currentTechniqueIds, selectedTechniqueIds);
    const newSelectedIds = _.difference(selectedTechniqueIds, currentTechniqueIds);
    const promises = [];
    console.log(oldSelectedIds);
    console.log(newSelectedIds);
    if (oldSelectedIds.length) {
      promises.push(deleteIdwgTechniqueItems(oldSelectedIds));
    }

    if (newSelectedIds.length) {
      promises.push(addIdwgTechniqueItems(newSelectedIds));
    }

    if (promises.length) {
      startLoading();
      try {
        for (const asyncCall of promises) {
          await asyncCall;
        }

        setTechniques(techniquesResult.data?.refEventTechniques.filter(item => selectedTechniqueIds.includes((item as RefEventTechnique).id)));
        AppNotifications.success(t('message.success.idwgUpdated'));
      } catch (err) {
        AppNotifications.error(t('message.error.default.title'));
      }

      stopLoading();
    }
  };

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    if (field === 'idwg') {
      value = (value as string).toUpperCase();
    }

    const editedIdwg = {...editIdwg};
    setObjValueByPath(editedIdwg, field, value);
    setEditIdwg(editedIdwg);
    if (update) {
      updateDataField(field, value);
    }
  };

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit();

      setSubmitRequested(false);
    }
  }, [submitRequested]);

  useEffect(() => {
    setTechniques(idwgTechniquesResult.data?.refEventTechniques as RefEventTechnique[]);
  }, [idwgTechniquesResult]);

  if (!editIdwg) {
    return null;
  }

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps, isDisabled} = props ?? {};
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedIdwg = _.cloneDeep(editIdwg);
            if (foreignField) {
              setObjValueByPath(editedIdwg!, foreignField!, item);
            }

            if (field) {
              setObjValueByPath(editedIdwg!, field!, item?.id ?? null);
            }

            setEditIdwg(editedIdwg);
            updateDataField(field!, item?.id ?? null);

            handleFieldBlur();
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          disabled: isDisabled || inputProps?.disabled,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    );
  };

  const updateIdwgState = (newData: Partial<InspectionDrawing>) => {
    const newIdwg = {
      ...editIdwg,
      ...newData,
    } as Partial<InspectionDrawing>;

    updateInspectionDrawing(newIdwg);
    setUpdateData({});
    fetchInspectionDrawings?.();
  };

  const handleIdwgUpdateError = (err?: Error) => {
    let errorMessage: string = t('message.error.default.title');
    if (typeof err?.message === 'string') {
      if (err.message.includes('Unique constraint failed on the fields: (`idwg`)')) {
        if (AppNotifications.timeoutId) {
          clearTimeout(AppNotifications.timeoutId);
        }

        errorMessage = t('message.error.unique.documentation.inspectionDrawing.idwg');
        updateFieldError('idwg', true);
        form.setError('idwg', {
          message: errorMessage,
        });
        return;
      }
    }

    AppNotifications.error(errorMessage);
  };

  const handleUpdateIdwg = () => updateIdwgByIdApi({variables: {id: idwg!.id, data: updateData}}).then(newData => {
    const updatedIdwg = newData?.data?.updateOneInspectionDrawing as Partial<InspectionDrawing>;
    AppNotifications.success(t('message.success.idwgUpdated'));
    const idwgNumberChanged = updatedIdwg.idwg && updatedIdwg.idwg !== activeInspectionDrawing?.idwg;
    // If idwg number changed, modify last url segment to set the new idwg number
    if (idwgNumberChanged) {
      navigate(ROUTE_DOCUMENTATION_IDWG_DETAIL.replace(/:number/, updatedIdwg.idwg!));
      return;
    }

    updateIdwgState(updatedIdwg);
  }).catch(err => {
    handleIdwgUpdateError(err);
  }).finally(() => {
    stopLoading();
  });

  const handleFieldBlur = () => {
    setSubmitRequested(true);
  };

  const handleFormSubmitSucess = () => {
    // Submit changes only if there are pending value changes
    if (updateData && Object.keys(updateData).length) {
      handleUpdateIdwg();
    }
  };

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: 'label.identification',
      fields: [
        {
          label: 'label.reference',
          field: 'idwg',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: idwg!.idwg,
          value: editIdwg!.idwg,
          isRequired: true,
          hasError: hasFieldError('idwg'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.IDWG,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.description',
          field: 'description',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: idwg!.description,
          value: editIdwg!.description,
          isRequired: true,
          hasError: hasFieldError('description'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.revision',
          field: 'revision',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: idwg!.revision,
          value: editIdwg!.revision,
          hasError: hasFieldError('revision'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.REVISION,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
        {
          label: 'label.type',
          field: 'typeId',
          initialValue: renderCodeAndDescription({code: idwg!.type?.type, description: idwg!.type?.description} as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({code: editIdwg!.type?.type, description: editIdwg!.type?.description} as TMaybeCodeDescriptionDatas),
          foreignObject: editIdwg.type,
          foreignField: 'type',
          className: 'flex w-full',
          dbValue: idwg!.typeId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: dwgTypesQueryResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllRefDwgTypesQuery>)?.data?.refDwgTypes ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription({code: (field as RefDwgType)?.type, description: (field as RefDwgType)?.description} as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        {
          label: 'label.sector',
          field: 'sectorId',
          isRequired: true,
          initialValue: renderCodeAndDescription({code: idwg!.sector?.sector, description: idwg!.sector?.description} as TMaybeCodeDescriptionDatas),
          value: renderCodeAndDescription({code: editIdwg!.sector?.sector, description: editIdwg!.sector?.description} as TMaybeCodeDescriptionDatas),
          foreignObject: editIdwg.sector,
          foreignField: 'sector',
          className: 'flex w-full',
          dbValue: idwg!.sectorId,
          fieldType: EFieldType.autocomplete,
          itemsQueryResult: sectorsQueryResult,
          getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllSectorsQuery>)?.data?.sectors ?? [],
          renderMenuItemLabel: field => renderCodeAndDescription({code: (field as Sector)?.sector, description: (field as Sector)?.description} as TMaybeCodeDescriptionDatas),
          renderInput: renderAutocomplete,
        },
        // {
        //   label: 'label.technique',
        //   field: 'technique',
        //   fieldType: EFieldType.multivalue,
        //   className: 'w-full',
        //   value: techniques?.map(item => ({value: item.id, label: `[${item.technique}] - ${item.description}`})) ?? [],
        //   hasError: hasFieldError('technique'),
        //   multiValueProps: {
        //     onChange: onValidateTechniqueSelection,
        //   },
        //   items: ((techniquesResult.data?.refEventTechniques ?? []) as RefEventTechnique[]).map((evtTech: RefEventTechnique) => ({
        //     value: evtTech.id,
        //     label: `[${evtTech.technique}] - ${evtTech.description}`,
        //   })) as unknown as Option[],
        // },
        {
          label: 'label.fileName',
          field: 'docName',
          fieldType: EFieldType.text,
          className: 'w-full',
          initialValue: idwg!.docName,
          value: editIdwg!.docName,
          hasError: hasFieldError('docName'),
          inputProps: {
            maxLength: MAX_LENGTH_VALIDATORS.DOCNAME,
          } as InputHTMLAttributes<HTMLInputElement>,
        },
      ],
    },
  ];

  return (
    <div className='flex flex-col h-full w-full overflow-hidden'>
      <RadForm {...form}>
        <form
          ref={htmlForm}
          onSubmit={form.handleSubmit(handleFormSubmitSucess)}
        >
          <div className='flex flex-col'>
            <FormGroupHeader>
              <div className='flex items-center gap-1'>
                <LuClipboardList/>

                {t('label.general')}
              </div>
            </FormGroupHeader>

            <FormFieldsBlock
              isFormContext
              className='text-gray-700'
              fieldsBlocks={fieldBlocks}
              onFieldChange={handleFieldChange}
              onFieldBlur={handleFieldBlur}
            />
          </div>
        </form>
      </RadForm>
    </div>
  );
}
