import {FetchResult, useLazyQuery, useQuery} from '@apollo/client';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import {Damage, EFlagStatus, FunctionalLocation, GetAllFlocsAutocompleteQuery, GetAllRefDamageClassesQuery, GetAllRefDamageCodeGroupsQuery, GetAllRefEventTechniquesQuery, GetAllRefFlocCatalogsQuery, GetAllRefFlocPartsQuery, GetFlocsByIdwgIdQuery, GetGridsByIdwgIdQuery, GetGridsBySectorIdQuery, Grid, RefDamageClass, RefDamageCodeGroup, RefEventTechnique, RefFlocCatalogs, RefFlocPart} from '@app/graphql/__types__/graphql';
import {DAMAGE_REF_CLASSES_GET_MANY, DAMAGE_REF_CODE_GROUPS_GET_MANY, FLOCS_GET_BY_IDWG_ID, FLOC_REF_PARTS_GET_MANY, GRIDS_GET_BY_IDWG_ID, GRIDS_GET_BY_SECTOR_ID, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY} from '@app/graphql/requests';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import {TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps} from '@app/types/app';
import {EFLOC_CATALOGS_CATEGORY, EFieldType} from '@app/utils/enums';
import {handleFormInputKeydown, isStringNotEmpty, renderCodeAndDescription, setObjValueByPath} from '@app/utils/functions';
import React, {InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {OBJ_NEW_ID} from '@app/utils/constants';
import useDamageStore, {MAX_LENGTH_VALIDATORS} from '@app/stores/damage';
import GridSelectionModal from '../../../Grid/GridBlock/GridSelectionModal';
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {LuClipboardList} from 'react-icons/lu';
import {ZOD_DAMAGE_DATAS} from '@app/stores/damage';
type TGeneralInfo = Readonly<{
  damage: Partial<Damage>;
  readonly?: boolean;
  onFieldUpdated?: (field: string, value: unknown) => void;
  onFieldBlur?: (field: string, value: unknown) => void;
}>;

export default function GeneralInfo({damage, onFieldUpdated, onFieldBlur, readonly}: TGeneralInfo) {
  const [codeGroupClass, setCodeGroupClass] = useState<Partial<RefDamageClass>>();
  const [grids, setGrids] = useState<Partial<Grid>[]>([]);
  const [flocSelectionModalDisplayed, changeFlocSelectionModalDisplay] = useState<boolean>(false);
  const [gridSelectionModalDisplayed, changeGridSelectionModalDisplay] = useState<boolean>(false);
  const {editDamage, setEditDamage, updateDamageDataField: updateDataField, hasFieldError, updateDamageData: updateData} = useDamageStore();
  const {t} = useTranslation();
  const refEventTechniqueResult = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY);
  const refDamageClassResult = useQuery<GetAllRefDamageClassesQuery>(DAMAGE_REF_CLASSES_GET_MANY);
  const [getFlocsByIdwgIdApi, flocsResult] = useLazyQuery<GetFlocsByIdwgIdQuery>(FLOCS_GET_BY_IDWG_ID);
  const refDamageCodeGroupResult = useQuery<GetAllRefDamageCodeGroupsQuery>(DAMAGE_REF_CODE_GROUPS_GET_MANY, {
    variables: {
      orderBy: [
        {
          codeGroup: 'asc',
        },
        {
          description: {
            sort: 'asc',
          },
        },
      ],
    },
  });
  const refFlocPartResult = useQuery<GetAllRefFlocPartsQuery>(FLOC_REF_PARTS_GET_MANY, {
    variables: {
      orderBy: [
        {
          part: 'asc',
        },
        {
          description: {
            sort: 'asc',
          },
        },
      ],
    },
  });
  const [getGridsByIdwgIdApi] = useLazyQuery<GetGridsByIdwgIdQuery>(GRIDS_GET_BY_IDWG_ID, {
    variables: {
      idwgId: damage.idwgId,
    },
    fetchPolicy: 'no-cache',
  });
  const [getGridsBySectorIdApi] = useLazyQuery<GetGridsBySectorIdQuery>(GRIDS_GET_BY_SECTOR_ID, {
    variables: {
      sectorId: damage.functionalLocation?.sectorId,
    },
    fetchPolicy: 'no-cache',
  });
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY, {
    variables: {
      orderBy: [
        {
          code: {
            sort: 'asc',
          },
        },
        {
          description: {
            sort: 'asc',
          },
        },
      ],
    },
  });
  const isNewForm = damage.id === OBJ_NEW_ID;
  const zodFormObject = z.object(ZOD_DAMAGE_DATAS(t));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), values: {
      position: editDamage?.position?.toString() ?? '',
      description: editDamage?.description?.toString() ?? '',
      longDescription: editDamage?.longDescription?.toString() ?? '',
      flocId: editDamage?.flocId ?? OBJ_NEW_ID,
      codeGroupId: editDamage?.codeGroupId ?? OBJ_NEW_ID,

    }, mode: 'onBlur'});
  const htmlForm = useRef<HTMLFormElement | null>(null);

  const handleSelectFloc = (items: Partial<FunctionalLocation>[]) => {
    const item = items.length ? items[0] : null;
    const editedDamage = {...editDamage};
    setObjValueByPath(editedDamage, 'functionalLocation', item);
    setObjValueByPath(editedDamage, 'flocId', item?.id ?? null);
    setEditDamage(editedDamage);
    updateDataField('flocId', item?.id ?? null);
  };

  const handleSelectGrid = (items: Partial<Grid>[]) => {
    const item = items.length ? items[0] : null;
    const editedDamage = {...editDamage};
    setObjValueByPath(editedDamage, 'grid', item);
    setObjValueByPath(editedDamage, 'gridId', item?.id ?? null);
    setEditDamage(editedDamage);
    updateDataField('gridId', item?.id ?? null);
  };

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    const editedIdwg = {...editDamage};
    setObjValueByPath(editedIdwg, field, value);
    setEditDamage(editedIdwg);
    if (update) {
      updateDataField(field, value);
    }

    onFieldUpdated?.(field, value);
  };

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps} = props ?? {};
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedDamage = {...editDamage};
            setObjValueByPath(editedDamage, foreignField!, item);
            setObjValueByPath(editedDamage, field!, item?.id ?? null);
            setEditDamage(editedDamage);
            updateDataField(field!, item?.id ?? null);
            if (field === 'codeGroupId') {
              if (!isStringNotEmpty(editedDamage.description)) {
                const defaultDesc = (item as Partial<RefDamageCodeGroup>).defaultDesc ?? '';
                setObjValueByPath(editedDamage, 'description', defaultDesc);
                setEditDamage(editedDamage);
                updateDataField('description', defaultDesc);
                onFieldUpdated?.('description', defaultDesc);
              }

              const defaultTechId = (item as Partial<RefDamageCodeGroup>).defaultTechniqueId;
              if (defaultTechId) {
                setObjValueByPath(editedDamage, 'techniqueId', defaultTechId);
                setObjValueByPath(editedDamage, 'technique', refEventTechniqueResult.data?.refEventTechniques.find(tech => (tech as RefEventTechnique).id === defaultTechId));
                setEditDamage(editedDamage);
                updateDataField('techniqueId', defaultTechId);
                onFieldUpdated?.('techniqueId', defaultTechId);
              }

              setCodeGroupClass((item as Partial<RefDamageCodeGroup>).class);
            }

            onFieldUpdated?.(field, item?.id ?? null);
            onFieldBlur?.(field, item?.id ?? null);
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    );
  };

  useEffect(() => {
    setCodeGroupClass(damage?.codeGroup?.class);
    if (isNewForm || damage.flagStatus === EFlagStatus.Y) {
      getFlocsByIdwgIdApi({
        variables: {
          idwgId: damage.idwgId,
        },
      });
    }

    if (damage.idwgId) {
      getGridsByIdwgIdApi().then(result => setGrids((result.data?.grids ?? []) as Partial<Grid>[]));
    } else {
      getGridsBySectorIdApi().then(result => setGrids((result.data?.grids ?? []) as Partial<Grid>[]));
    }
  }, [damage.id]);

  const BLOCK_IDENTIFICATION: TFieldsBlock = {
    title: 'label.identification',
    fields: [
      isNewForm || damage.flagStatus === EFlagStatus.Y ? {
        label: 'label.functionalLocation',
        field: 'flocId',
        initialValue: renderCodeAndDescription({description: damage!.functionalLocation?.description, code: damage!.functionalLocation?.floc}),
        value: renderCodeAndDescription({description: editDamage?.functionalLocation?.description, code: editDamage?.functionalLocation?.floc}),
        foreignObject: editDamage?.functionalLocation,
        foreignField: 'functionalLocation',
        isRequired: true,
        dropdownTransitionClassName: 'top-auto bottom-6 hidden',
        listProps: {
          hasVirtualScroll: true,
          virtualScrollItemSize: 70,
        },
        inputProps: {
          readOnly: true,
          onClick(_e: React.MouseEvent<HTMLElement>) {
            changeFlocSelectionModalDisplay(true);
          },
        },
        dbValue: damage!.flocId as number,
        hasError: hasFieldError('flocId'),
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocsResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllFlocsAutocompleteQuery>)?.data?.functionalLocations ?? [],
        renderMenuItemLabel: field => (field as Partial<FunctionalLocation>).floc,
        renderInput: renderAutocomplete,
      } : {
        label: 'label.functionalLocation',
        field: 'functionalLocation.floc',
        fieldType: EFieldType.text,
        initialValue: damage!.functionalLocation?.floc,
        value: editDamage?.functionalLocation?.floc,
        isDisabled: true,
      },
      {
        label: 'label.position',
        field: 'position',
        fieldType: EFieldType.text,
        initialValue: damage?.position ?? '',
        value: editDamage?.position ?? '',
        hasError: hasFieldError('position'),
        isRequired: true,
        inputComponent: 'input',
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.POSITION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.description',
        field: 'description',
        fieldType: EFieldType.text,
        hasError: hasFieldError('description'),
        isRequired: true,
        initialValue: damage!.description,
        value: editDamage?.description,
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.longDescription',
        field: 'longDescription',
        fieldType: EFieldType.text,
        hasError: hasFieldError('longDescription'),
        initialValue: damage!.longDescription,
        value: editDamage?.longDescription,
        inputComponent: 'textarea',
        inputProps: {
          rows: 2,
          maxLength: MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION,
        } as TextareaHTMLAttributes<HTMLTextAreaElement>,
      },
      // {
      //   label: 'label.externalID',
      //   field: 'externalRef',
      //   isDisabled: true,
      //   fieldType: EFieldType.text,
      //   initialValue: damage!.externalRef,
      //   inputComponent: 'input',
      // },
    ],
  };

  const BLOCK_CLASSIFICATION: TFieldsBlock = {
    title: 'label.classification',
    fields: [
      {
        label: 'label.class',
        field: 'classId',
        initialValue: renderCodeAndDescription({description: codeGroupClass?.description, code: codeGroupClass?.class}),
        value: renderCodeAndDescription({description: codeGroupClass?.description, code: codeGroupClass?.class}),
        foreignObject: codeGroupClass,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refDamageClassResult,
        isRequired: true,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefDamageClassesQuery>)?.data?.refDamageClasses ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefDamageClass>).description, code: (field as Partial<RefDamageClass>).class}),
        onSelect(item) {
          const editedDamage = {...editDamage};
          setObjValueByPath(editedDamage, 'codeGroup', null);
          setObjValueByPath(editedDamage, 'codeGroupId', null);
          setEditDamage(editedDamage);
          updateDataField('codeGroupId', null);
          setCodeGroupClass(item as Partial<RefDamageClass>);
        },
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.codeGroup',
        field: 'codeGroupId',
        initialValue: renderCodeAndDescription({description: damage!.codeGroup?.description, code: damage!.codeGroup?.codeGroup}),
        value: renderCodeAndDescription({description: editDamage?.codeGroup?.description, code: editDamage?.codeGroup?.codeGroup}),
        foreignObject: editDamage?.codeGroup,
        foreignField: 'codeGroup',
        hasError: hasFieldError('codeGroupId'),
        isRequired: true,
        dbValue: damage!.codeGroupId as number,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refDamageCodeGroupResult,
        getItemsFromResult: (result: FetchResult) => ((result as FetchResult<GetAllRefDamageCodeGroupsQuery>)?.data?.refDamageCodeGroups ?? []).filter((item: unknown) => !codeGroupClass || (item as RefDamageCodeGroup).classId === codeGroupClass.id),
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefDamageCodeGroup>).description, code: (field as Partial<RefDamageCodeGroup>).codeGroup}),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.technique',
        field: 'techniqueId',
        initialValue: renderCodeAndDescription({description: damage!.technique?.description, code: damage!.technique?.technique}),
        value: renderCodeAndDescription({description: editDamage?.technique?.description, code: editDamage?.technique?.technique}),
        foreignObject: editDamage?.technique,
        foreignField: 'technique',
        dbValue: damage?.techniqueId as number,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refEventTechniqueResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefEventTechniquesQuery>)?.data?.refEventTechniques ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefEventTechnique>).description, code: (field as Partial<RefEventTechnique>).technique}),
        renderInput: renderAutocomplete,
      },
    ],
  };
  const BLOCK_ATTRIBUTES: TFieldsBlock = {
    title: 'label.attributes',
    fields: [
      {
        label: 'label.part',
        field: 'partId',
        initialValue: renderCodeAndDescription({description: damage!.part?.description, code: damage!.part?.part}),
        value: renderCodeAndDescription({description: editDamage?.part?.description, code: editDamage?.part?.part}),
        foreignObject: editDamage?.part,
        foreignField: 'part',
        dbValue: damage!.partId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refFlocPartResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefFlocPartsQuery>)?.data?.refFlocParts ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefFlocPart>).description, code: (field as Partial<RefFlocPart>).part}),
        renderInput: renderAutocomplete,
      },
    ],
  };
  const BLOCK_LOCATION: TFieldsBlock = {
    title: 'label.location',
    fields: [
      {
        label: 'label.grid',
        field: 'gridId',
        initialValue: renderCodeAndDescription({description: damage!.grid?.description, code: damage!.grid?.grid}),
        value: renderCodeAndDescription({description: editDamage?.grid?.description, code: editDamage?.grid?.grid}),
        foreignObject: editDamage?.grid,
        foreignField: 'grid',
        dbValue: damage!.gridId,
        fieldType: EFieldType.autocomplete,
        dropdownTransitionClassName: 'hidden',
        items: grids,
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<Grid>).description, code: (field as Partial<Grid>).grid}),
        renderInput: renderAutocomplete,
        inputProps: {
          readOnly: true,
          onClick(_e: React.MouseEvent<HTMLElement>) {
            changeGridSelectionModalDisplay(true);
          },
        },
      },
      {
        label: 'label.accessibility',
        field: 'accessibilityId',
        initialValue: renderCodeAndDescription(damage!.accessibility as TMaybeCodeDescriptionDatas),
        value: renderCodeAndDescription(editDamage!.accessibility as TMaybeCodeDescriptionDatas),
        foreignObject: editDamage?.accessibility,
        foreignField: 'accessibility',
        dbValue: damage!.accessibilityId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocCatalogResult,
        getItemsFromResult: (result: FetchResult) => ((result as FetchResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? []).filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.ACCESSIBILITY),
        renderMenuItemLabel: field => renderCodeAndDescription(field as TMaybeCodeDescriptionDatas),
        renderInput: renderAutocomplete,
      },
    ],
  };

  const fieldBlocks = [BLOCK_IDENTIFICATION, BLOCK_CLASSIFICATION, BLOCK_ATTRIBUTES, BLOCK_LOCATION];

  return (
    <div className='gap-2 px-2 py-2 w-full flex flex-col'>
      <RadForm {...form}>
        <form
          ref={htmlForm}
        >
          <FormFieldsBlock
            isDisabled={readonly}
            prefixNode={<FormGroupHeader>
              <div className='flex items-center font-bold'>
                <LuClipboardList size={20} className='mr-2'/> {t('label.headerData')}
              </div>
            </FormGroupHeader>}
            fieldsBlocks={fieldBlocks}
            onFieldChange={handleFieldChange}
            onFieldBlur={onFieldBlur}/>
        </form>
      </RadForm>
      {gridSelectionModalDisplayed && <GridSelectionModal hasItems open items={grids} isMultiple={false} onValidate={handleSelectGrid} onClose={() => changeGridSelectionModalDisplay(false)}/> }
      {flocSelectionModalDisplayed && <FlocSelectionModal open hasItems queryResult={flocsResult} items={flocsResult.data?.functionalLocations ?? []} isMultiple={false} onValidate={handleSelectFloc} onClose={() => changeFlocSelectionModalDisplay(false)}/> }

    </div>
  );
}
