import {useMutation} from '@apollo/client';
import {DeleteIdwgTechniqueByIdwgIdAndTechniqueIdMutation, InspectionDrawing, RefEventTechnique} from '@app/graphql/__types__/graphql';
import {DWG_IDWG_TECHNIQUE_DELETE_BY_IDWG_ID_AND_TECHNIQUE_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {Badge} from '@holis/react-ui';
import {RadContextMenu, RadContextMenuTrigger, RadButton, RadContextMenuContent, RadContextMenuItem} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';
import {LuFlagOff, LuLayers, LuTrash2} from 'react-icons/lu';

type TTechniqueFilterButton = Readonly<{
  idwg: Partial<InspectionDrawing>;
  refEventTechnique?: Partial<RefEventTechnique>;
  onDelete?: (techniqueId: number) => void;
  onFilterClick?: () => void;
  counter?: number;
  realCounter?: number;
  active: boolean;
}>;

export default function TechniqueFilterButton({refEventTechnique, counter, idwg, onDelete, onFilterClick, active, realCounter}: TTechniqueFilterButton) {
  const [removeIdwgTechniqueApi] = useMutation<DeleteIdwgTechniqueByIdwgIdAndTechniqueIdMutation>(DWG_IDWG_TECHNIQUE_DELETE_BY_IDWG_ID_AND_TECHNIQUE_ID);

  const {t} = useTranslation();

  const handleRemoveIdwgTechnique = () => {
    const techniqueId = refEventTechnique!.id!;
    removeIdwgTechniqueApi({
      variables: {
        idwgId: idwg.id!,
        techniqueId,
      },
    }).then(() => {
      onDelete?.(techniqueId);
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  return (
    <RadContextMenu>
      <RadContextMenuTrigger disabled={!refEventTechnique?.id}>
        <RadButton variant='outline' className={`w-[30px] h-[30px] px-1 py-1 relative ${active ? 'bg-blue-50 text-blue-700' : ''}`} onClick={onFilterClick}>
          {refEventTechnique ? <LuLayers/> : <LuFlagOff/>}
          {typeof counter !== 'undefined' && <Badge shapePreset={Badge.ShapePresets.Pill} className={`absolute text-xs -top-2 -right-2 ${active ? 'bg-blue-50 text-blue-700' : ''} border w-[20px] h-[20px]`}>{counter}</Badge>}
          <Badge className={`absolute -bottom-5 ${active ? 'font-bold' : 'font-normal'}`}>{refEventTechnique?.technique ?? t('label.none')}</Badge>
        </RadButton>
      </RadContextMenuTrigger>
      <RadContextMenuContent>
        <RadContextMenuItem className='item-destructive' disabled={!!realCounter} onClick={handleRemoveIdwgTechnique}>
          <LuTrash2 className='mr-2'/> {t('label.deleteIdwgTechnique')}
        </RadContextMenuItem>
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
