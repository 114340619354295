import {useQuery, FetchResult, useLazyQuery} from '@apollo/client';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import BlockTitle from '@app/components/Common/Text/BlockTitle';
import {Event, EventCml, EventCmlUncheckedUpdateInput, GetAllRefCmlValCodesQuery, GetEventCmlByIdQuery, Cml, RefCmlValCode} from '@app/graphql/__types__/graphql';
import {CML_REF_VAL_CODES_GET_MANY, WORKORDER_EVENT_CMLS_GET_BY_ID} from '@app/graphql/requests';
import {TFieldsBlock, TRenderAutocompleteProps} from '@app/types/app';
import {EFieldType} from '@app/utils/enums';
import {calculateCmlCodeCond, isTHKPoint, isValidUnit, renderQualReadingItem, setObjValueByPath, stringNumFormat} from '@app/utils/functions';
import React, {TextareaHTMLAttributes, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import {LuFileEdit} from 'react-icons/lu';
import _ from 'lodash';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
type TMeasurementBlock = Readonly<{
  eventCml: Partial<EventCml>;
  event: Partial<Event>;
  cml: Partial<Cml>;
  readonly?: boolean;
  onFieldUpdated?: (field: string, value: unknown) => void;
  onFieldBlur?: (field: string, value: unknown) => void;
  onMeasurementReadingUpdated?: (qualReadingId: number, quantReading: unknown) => void;
}>

const MAX_LENGTH_VALIDATORS = {
  notes: 600,
  quantReading: 100,
};

export default function MeasurementBlock({event, eventCml, cml, onFieldUpdated, onFieldBlur, readonly, onMeasurementReadingUpdated}: TMeasurementBlock) {
  const {t} = useTranslation();
  const config = useOptimusConfig();
  const [valCodes, setValCodes] = useState<Partial<RefCmlValCode>[]>([]);
  const [updateData, setUpdateData] = useState<EventCmlUncheckedUpdateInput>({});
  const refCmlValCodesResult = useQuery<GetAllRefCmlValCodesQuery>(CML_REF_VAL_CODES_GET_MANY);
  const [activeEventCml, setActiveEventCml] = useState<Partial<EventCml>>(eventCml);
  const [initEventCml, setInitEventCml] = useState<Partial<EventCml>>(eventCml);
  const [getEventCmlByIdApi] = useLazyQuery<GetEventCmlByIdQuery>(WORKORDER_EVENT_CMLS_GET_BY_ID, {
    fetchPolicy: 'no-cache',
  });
  const zodFormObject = z.object({
    notes: z.string().max(MAX_LENGTH_VALIDATORS.notes),
  });
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), values: {
      notes: activeEventCml?.notes?.toString() ?? '',
    }, mode: 'onBlur'});
  const htmlForm = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    setActiveEventCml(eventCml);
    setInitEventCml(eventCml);
  }, [eventCml]);

  useEffect(() => {
    if (cml.codeGroup && !refCmlValCodesResult.loading && !refCmlValCodesResult.error && refCmlValCodesResult.data?.refCmlValCodes && initEventCml) {
      let newValCodes = ((refCmlValCodesResult?.data?.refCmlValCodes ?? []) as Partial<RefCmlValCode>[]).filter((valC: Partial<RefCmlValCode>) => !cml.codeGroup || !!cml.codeGroup.codeGroupValCodes?.map(cgVal => cgVal.valCodeId).includes(valC.id!)) ?? [];
      if (isTHKPoint(cml)) {
        const codeCond = calculateCmlCodeCond(cml, !isNaN(parseFloat(initEventCml.quantReading)) ? parseFloat(initEventCml.quantReading) : undefined);
        newValCodes = newValCodes.filter(valCode => valCode.codeCond === codeCond);
      }

      if (_.difference(newValCodes.map(item => item.id), valCodes.map(item => item.id)).length) {
        setValCodes(newValCodes);
      }
    }
  }, [eventCml?.cml?.alarm3, cml.codeGroup, refCmlValCodesResult, initEventCml?.quantReading]);

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {setInputValue, renderMenuItemLabel, dbValue, field, foreignField} = props ?? {};

    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedCml = {...activeEventCml};
            setObjValueByPath(editedCml, foreignField!, item);
            setObjValueByPath(editedCml, field!, item?.id ?? null);
            setActiveEventCml(editedCml);
            setUpdateData({
              [field!]: {
                set: item?.id ?? null,
              },
            });
            onFieldUpdated?.(field!, item?.id ?? null);
            onMeasurementReadingUpdated?.(item!.id as number, activeEventCml.quantReading);

            onFieldBlur?.(field!, item?.id ?? null);
          }
        }}
        {...props}
      />
    );
  };

  const fieldBlocks:TFieldsBlock[] = [
    {
      title: false,
      fields: [
        ...(!isValidUnit(cml.codeGroup?.unit) ? [] : [{
          label: 'label.measurement',
          field: 'quantReading',
          fieldType: EFieldType.number,
          inputRestrictDecimal: true,
          initialValue: initEventCml?.quantReading ?? '',
          value: activeEventCml?.quantReading ?? '',
          className: 'w-[300px] mr-6',
          labelClassName: 'w-[120px]',
          isDisabled: !isValidUnit(cml.codeGroup?.unit),
          labelInputProps: {
            suffixInputComponent: isValidUnit(cml.codeGroup?.unit) ? <span className='ml-1 w-full flex flex-col justify-center lowercase'>{cml.codeGroup!.unit!.toLowerCase()}</span> : undefined,
          },
        }]),
        {
          label: 'label.reading',
          field: 'qualReadingId',
          foreignField: 'qualReading',
          foreignObject: activeEventCml?.qualReading ?? initEventCml?.qualReading,
          fieldType: EFieldType.autocomplete,
          dbValue: activeEventCml.qualReadingId,
          itemsQueryResult: refCmlValCodesResult,
          getItemsFromResult: (_result: FetchResult) => valCodes,
          renderMenuItemLabel: field => renderQualReadingItem(field as Partial<RefCmlValCode>),
          renderInput: renderAutocomplete,
          className: `flex-1 flex ${!isValidUnit(cml.codeGroup?.unit) ? 'min-w-full' : 'min-w-[calc(100%-330px)]'}`,
          labelClassName: 'w-[100px]',
          isRequired: true,
        },
        {
          label: 'label.date',
          field: 'reportingDate',
          fieldType: EFieldType.date,
          initialValue: initEventCml?.reportingDate,
          value: activeEventCml?.reportingDate,
          className: 'w-[300px] mr-6',
          isDisabled: true,
          labelClassName: isValidUnit(cml.codeGroup?.unit) ? 'w-[120px]' : 'w-[100px]',
        },
        {
          label: 'label.reader',
          field: 'reader',
          fieldType: EFieldType.text,
          initialValue: initEventCml?.reader,
          value: activeEventCml?.reader,
          className: 'flex flex-1 min-w-[calc(100%-330px)]',
          isDisabled: true,
          labelClassName: 'w-[100px]',
        },
        {
          label: 'label.notes',
          field: 'notes',
          fieldType: EFieldType.text,
          initialValue: initEventCml?.notes,
          value: activeEventCml?.notes,
          labelClassName: 'w-[110px]',
          className: 'flex w-full',
          inputComponent: 'textarea',
          inputProps: {
            rows: 2,
            maxLength: MAX_LENGTH_VALIDATORS.notes,
          } as TextareaHTMLAttributes<HTMLTextAreaElement>,
        },
      ],
      fieldsClassName: 'w-full flex flex-row flex-wrap gap-0',
      fieldClassName: 'inline-flex w-auto',
    },
  ];

  const handleFieldChange = (field: string, value: unknown, blur = false) => {
    if (field === 'qualReadingId') {
      return;
    }

    if (field === 'quantReading') {
      value = stringNumFormat(value as string);
    }

    const editedCml = {...activeEventCml};
    setObjValueByPath(editedCml, field, value);
    const newUpdateData: EventCmlUncheckedUpdateInput = {
      [field!]: {
        set: value?.toString() ?? null,
      },
    };
    let newValCodes: Partial<RefCmlValCode>[] | null = null;
    if (field === 'quantReading' && blur) {
      if (isTHKPoint(cml)) {
        newValCodes = ((refCmlValCodesResult?.data?.refCmlValCodes ?? []) as Partial<RefCmlValCode>[]).filter((valC: Partial<RefCmlValCode>) => !cml.codeGroup || !!cml.codeGroup.codeGroupValCodes?.map(cgVal => cgVal.valCodeId).includes(valC.id!)) ?? [];
        const codeCond = calculateCmlCodeCond(cml, !isNaN(parseFloat(String(value))) ? parseFloat(String(value)) : undefined);
        newValCodes = newValCodes.filter(valCode => valCode.codeCond === codeCond);
        let valCodeId = activeEventCml.qualReadingId;
        let valCode: Partial<RefCmlValCode> | undefined | null = activeEventCml.qualReading;
        if (!(valCodeId && newValCodes.find(item => item.id === valCodeId))) {
          if (newValCodes.length === 1) {
            valCode = newValCodes[0];
            valCodeId = newValCodes[0].id;
          } else {
            valCode = null;
            valCodeId = null;
          }
        }

        if (_.difference(newValCodes.map(item => item.id), valCodes.map(item => item.id)).length) {
          setValCodes(newValCodes);
          blur = false;
        }

        setObjValueByPath(editedCml, 'qualReadingId', valCodeId);
        setObjValueByPath(editedCml, 'qualReading', valCode);
        newUpdateData.qualReadingId = {
          set: valCodeId ?? null,
        };

        setUpdateData(newUpdateData);
        setInitEventCml(editedCml);

        onMeasurementReadingUpdated?.(valCodeId ?? 0, value);
      }
    }

    setActiveEventCml(editedCml);

    if (initEventCml && value !== initEventCml?.[field as keyof EventCml]) {
      setUpdateData(newUpdateData);
    }

    onFieldUpdated?.(field, value);

    if (blur) {
      onFieldBlur?.(field, value);
    }
  };

  const getEventCmlDetail = () => {
    if (eventCml.id) {
      getEventCmlByIdApi({
        variables: {
          id: eventCml.id!,
        },
        fetchPolicy: 'no-cache',
      }).then(fetchResult => {
        setInitEventCml({...fetchResult.data?.eventCml! as Partial<EventCml>});
        setActiveEventCml({...fetchResult.data?.eventCml! as Partial<EventCml>});
      });
    }
  };

  useEffect(() => {
    getEventCmlDetail();
  }, [eventCml.id]);

  return (
    <div className='flex flex-col w-full'>
      <BlockTitle className='uppercase text-primary px-4 font-bold'>
        <LuFileEdit size={20} className='mr-2'/> {t('label.measurement')} ({t('label.event')} {event!.event})
      </BlockTitle>
      <RadForm {...form}>
        <form
          ref={htmlForm}
        >
          <FormFieldsBlock
            isDisabled={readonly || config.getActionIsDisabled('event', 'measurement', event.status)}
            className='px-2'
            fieldsBlocks={fieldBlocks} onFieldChange={(f, v) => handleFieldChange(f, v, false)} onFieldBlur={(f, v) => handleFieldChange(f, v, true)}/>
        </form>
      </RadForm>
    </div>
  );
}
