import {Attachment, UpdateAttachmentByIdMutation} from '@app/graphql/__types__/graphql';
import {useDropzone} from 'react-dropzone';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import React, {useEffect, useRef, useState} from 'react';
import {useDataStore} from '@app/stores/data';
import {AxiosResponse} from 'axios';
import {useLayoutStore} from '@app/stores/layout';
import {EDownloadFileType, EImportService} from '@app/utils/enums';
import {useMutation} from '@apollo/client';
import {FILE_ATTACHMENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useSortable} from '@dnd-kit/sortable';
import useAttachmentStore, {MAX_LENGTH_VALIDATORS, ZOD_ATTACHMENT_DATAS} from '@app/stores/attachment';
import ItemCard, {ItemCardContent} from '../../ItemCard';
import Actions from './Actions';
import {RadForm, RadFormField, RadInput} from '@holis/react-ui/rad';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import DragHandle from '../../ItemCard/DragHandle';
import {CSS} from '@dnd-kit/utilities';
import {OptimusClientConfig} from '@app/utils/clientConfig';

type TAttachmentCard = Readonly<{
  attachment: Partial<Attachment>;
  hasActionButtons?: boolean;
  hasDragBtn?: boolean;
  readonly?: boolean;
  fetchAttachments?: () => void;
}>;

export default function AttachmentCard({attachment, hasDragBtn, hasActionButtons, readonly, fetchAttachments: fetchAttachmentList}: TAttachmentCard) {
  const {t} = useTranslation();
  const {fetchAttachments} = useAttachmentStore();
  const [menuActionsShown, setMenuActionsShown] = useState<boolean>(false);
  const {startLoading, stopLoading} = useLayoutStore();
  const {acceptedFiles, fileRejections, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: ['.png', '.jpeg', '.jpg', '.svg', '.pdf'],
    noClick: true,
    multiple: false,
  });
  const [updateAttachmentById] = useMutation<UpdateAttachmentByIdMutation>(FILE_ATTACHMENTS_UPDATE_BY_ID);

  const zodFormObject = z.object(ZOD_ATTACHMENT_DATAS(t));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), mode: 'onSubmit'});
  const htmlForm = useRef<HTMLFormElement | null>(null);

  const {uploadDownloadService} = useDataStore();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: attachment?.id!});
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const uploadImages = (file: File) => {
    startLoading();
    uploadDownloadService!.uploadFile(file, {id: attachment.id}, EImportService.UPLOAD_ATTACHMENT).then((res: AxiosResponse) => {
      if (res.status === 200) {
        (fetchAttachmentList ?? fetchAttachments)?.();
        AppNotifications.success(t('message.success.importService.attachmentUploaded'));
      } else {
        throw new Error(String(res.data));
      }
    }).catch((err: Error) => {
      console.log(err);
      AppNotifications.error(t('message.error.importService.attachmentUploadFailed'));
    }).finally(() => {
      stopLoading();
    });
  };

  const handleFiledBlur = (field: 'description' | 'attachmentName') => {
    if (form.getValues()[field] !== attachment[field]) {
      htmlForm.current?.requestSubmit();
    }
  };

  const handleFormSubmitSucess = (data: z.infer<typeof zodFormObject>) => {
    updateAttachmentById({
      variables: {
        id: attachment.id!,
        data: {
          attachmentName: {set: data.attachmentName},
          description: {set: data.description},
        },
      },
    }).then(() => {
      AppNotifications.success(t('message.success.attachmentUpdated'));
      (fetchAttachmentList ?? fetchAttachments)?.();
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  useEffect(() => {
    if (acceptedFiles.length || fileRejections.length) {
      if (fileRejections.length) {
        const {message} = fileRejections[0].errors[0];
        AppNotifications.error(message ?? t('message.error.default.title'));
      } else if (acceptedFiles.length) {
        uploadImages(acceptedFiles[0]);
      }
    }
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    form.reset({
      attachmentName: attachment.attachmentName as string,
      description: attachment.description ?? '',
    });
  }, [attachment]);

  const inputClassName = 'h-auto py-0 px-1';

  return (

    <div {...(getRootProps())} ref={setNodeRef} style={style}>
      <input ref={inputRef} {...getInputProps()}/>
      <RadForm {...form}>
        <form
          ref={htmlForm} onSubmit={form.handleSubmit(handleFormSubmitSucess, console.log)}
        >
          <ItemCard className={form.formState.isValid ? '' : 'border-red-400'}>

            <ItemCardContent className='flex items-center'>

              { hasDragBtn && <DragHandle listeners={listeners} attributes={attributes}/> }

              <div className='flex-1 flex flex-col h-full py-2 text-sm'>
                <RadFormField
                  name='attachmentName'
                  render={({field, fieldState}) => (
                    <RadInput
                      variant='holis'
                      className={`font-semibold mb-[2px] ${fieldState.error ? 'bg-red-50' : ''} ${inputClassName}`}
                      placeholder={t('label.name')}
                      {...field}
                      disabled={readonly}
                      maxLength={MAX_LENGTH_VALIDATORS.ATTACHMENT_NAME}
                      onBlur={() => handleFiledBlur('attachmentName')}/>
                  )}/>
                <RadFormField
                  name='description'
                  render={({field}) => (
                    <RadInput
                      variant='holis'
                      className={`line-clamp-1 ${inputClassName}`}
                      placeholder={t('label.description')}
                      {...field}
                      disabled={readonly}
                      maxLength={MAX_LENGTH_VALIDATORS.DESCRIPTION}
                      onBlur={() => handleFiledBlur('description')}/>
                  )}/>
              </div>

              {hasActionButtons
              && <Actions
                className={menuActionsShown ? 'z-1' : 'z-0'}
                downloadUrl={attachment.contentType ? `${OptimusClientConfig.current.fileBaseUrl}/${attachment.id}?type=${EDownloadFileType.ATTACHMENT}` : undefined}
                downloadFileName={`${attachment.attachmentName ?? attachment.id}.${attachment.extension}`}
                inputRef={inputRef}
                item={attachment}
                onMenuDisplayChange={setMenuActionsShown}/>}

            </ItemCardContent>

          </ItemCard>
        </form>
      </RadForm>
    </div>
  );
}

/*

<div ref={setNodeRef} style={style} className='flex border h-[48px] justify-between items-center w-full mt-2'>
      {(!!hasActionButtons || !!hasDragBtn) && <div className='flex w-[30px] items-center justify-end relative'>
        {!!hasDragBtn && <MdOutlineDragIndicator size={24} className='text-gray-300 hover:cursor-move' {...attributes} {...listeners}/>}
      </div>}
      <div className='flex items-center flex-grow hover:cursor-default gap-1' {...getRootProps()}>
        <input ref={inputRef} {...getInputProps()}/>
        <Badge shapePreset={Badge.ShapePresets.Square} className='bg-secondary text-white w-[48px] h-[48px] rounded-none'><FaLink/></Badge>
        <div className='flex flex-col gap-1 flex-grow'>
          <LabelValueRow value={attachmentName} className='h-[16px] w-full' label={false} inputProps={{className: 'h-[16px] text-xs w-full', name: 'attachmentName', onBlur: handleTextFieldBlur, placeholder: t('label.name')}} onTextInputChange={handleTextFieldChange}/>
          <LabelValueRow value={description} className='h-[16px] w-full' label={false} inputProps={{className: 'h-[16px] text-xs w-full', name: 'description', onBlur: handleTextFieldBlur, placeholder: t('label.description')}} onTextInputChange={handleTextFieldChange}/>
        </div>
      </div>

    </div>

    */
