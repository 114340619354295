import {Event, EventDamage, Damage, Picture, UpdateDamageByIdMutation, UpdateEventDamageByIdMutation, NotificationDamage, UpdateNotificationDamageMutation, GetLatestEventDamageByDmgIdQuery, GetNotificationDamagesByNotifIdQuery} from '@app/graphql/__types__/graphql';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {TCarouselApi} from '@holis/react-ui';
import {useEffect, useRef, useState} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import PictureCarousel from './components/pictures/PictureCarousel';
import GeneralInfo from './components/GeneralInfo';
import Actions from './components/Actions';
import useDamageStore from '@app/stores/damage';
import {EApiOperator} from '@app/utils/enums';
import {OBJ_NEW_ID} from '@app/utils/constants';
import DeleteDamageModal from './components/DeleteDamageModal';
import EventDamageHistory from './components/events/EventDamagesHistory';
import MeasurementBlock from './components/measurement';
import NotificationBlock from './components/Notification';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {useLazyQuery, useMutation} from '@apollo/client';
import {DAMAGES_UPDATE_BY_ID, NOTIFICATION_DAMAGES_GET_BY_NOTIF_ID, NOTIFICATION_DAMAGES_UPDATE, WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID, WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {useHolisAuth} from '@holis/auth-client-react';
import ClassPositionItemSelector from '@app/components/Common/ClassPositionItemSelector';
type TDamageModal = TMultiplePanelsModal<Partial<Damage>> & Readonly<{
  event?: Partial<Event>;
  eventDamage?: Partial<EventDamage>;
  notificationDamage?: Partial<NotificationDamage>;
  onCreatedOrUpdated?: (damage?: Partial<Damage>, operator?: EApiOperator) => void;
  onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
  allItems?: Partial<EventDamage | NotificationDamage | Damage>[];
}>

export default function DamageModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventDamage, notificationDamage, allItems, ...restProps}: TDamageModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const {user} = useHolisAuth();
  const [itemToOpen, setItemToOpen] = useState<Partial<Damage>>();
  const [setCurrentDateAndUser, setSetCurrentDateAndUser] = useState<boolean>(false);
  const {deleteDamageModalOpen, resetData, changeDeleteDamageModalDisplay, setActiveDamage, activeDamage} = useDamageStore();
  const dmgUpdatedDatas = useRef<Partial<Damage>>({});
  const evtDmgUpdatedDatas = useRef<Partial<EventDamage>>({});
  const notificationDamageUpdatedDatas = useRef<Partial<NotificationDamage>>({});
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [updateDamageByIdApi] = useMutation<UpdateDamageByIdMutation>(DAMAGES_UPDATE_BY_ID);
  const [updateEventDamageByIdApi] = useMutation<UpdateEventDamageByIdMutation>(WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID);
  const [updateNotificationDamageApi] = useMutation<UpdateNotificationDamageMutation>(NOTIFICATION_DAMAGES_UPDATE);
  const [getLatestEventDamageByDmgIdApi] = useLazyQuery<GetLatestEventDamageByDmgIdQuery>(WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID);
  const [getNotificationDamagesByDmgIdApi] = useLazyQuery<GetNotificationDamagesByNotifIdQuery>(NOTIFICATION_DAMAGES_GET_BY_NOTIF_ID);
  const [allItemsFetched, setAllItemsFetched] = useState<Partial<EventDamage | NotificationDamage>[]>();

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();

  const [editedDamage, setEditedDamage] = useState<Partial<Damage>>(item);
  const [editedEventDamage, setEditedEventDamage] = useState<Partial<EventDamage | undefined>>(eventDamage);
  const [editedNotificationDamage, setEditedNotificationDamage] = useState<Partial<NotificationDamage | undefined>>(notificationDamage);

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleDamageDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item, EApiOperator.DELETE);
  };

  const handleCloseModal = () => {
    resetData();

    if (itemToOpen) {
      setActiveDamage(itemToOpen);
      setItemToOpen(undefined);
      setIsModalOpened(true);
      return;
    }

    onOpenChange?.(false);
    restProps.onClose?.();
  };

  const handleFieldUpdated = (itemType: 'dmg' | 'meas', field: string, value: unknown) => {
    const isNotification = !!notificationDamage;

    if ((value as { id: string })?.id) {
      value = (value as { id: string }).id;
    }

    if (field === 'qualReadingId' && !isNotification) {
      setSetCurrentDateAndUser(true);
    }

    if (itemType === 'dmg') {
      dmgUpdatedDatas.current[field as keyof Damage] = value;
    } else if (isNotification) {
      if (field === 'qualReadingId') {
        notificationDamageUpdatedDatas.current.qualReadingId = Number(value);
      }
    } else {
      evtDmgUpdatedDatas.current[field as keyof EventDamage] = value;
    }
  };

  const handleSave = async () => {
    const updateDmgRequest: Record<string, unknown> = {};
    const updateEvtDmgRequest: Record<string, unknown> = {};
    const updateNotificationDamageRequest: Record<string, unknown> = {};

    Object.keys(dmgUpdatedDatas.current).forEach(key => {
      if (key === 'classId') {
        return;
      }

      updateDmgRequest[key] = {set: dmgUpdatedDatas.current[key as keyof Damage] ?? null};
    });
    Object.keys(evtDmgUpdatedDatas.current).forEach(key => {
      updateEvtDmgRequest[key] = {set: evtDmgUpdatedDatas.current[key as keyof EventDamage] ?? null};
    });
    Object.keys(notificationDamageUpdatedDatas.current).forEach(key => {
      updateNotificationDamageRequest[key] = {set: notificationDamageUpdatedDatas.current[key as keyof NotificationDamage] ?? null};
    });

    try {
      if (Object.keys(dmgUpdatedDatas.current).length) {
        const r = await updateDamageByIdApi({variables: {id: item.id!, data: updateDmgRequest}});
        setEditedDamage(r.data?.updateOneDamage as Partial<Damage>);
      }

      if (Object.keys(evtDmgUpdatedDatas.current).length) {
        updateEvtDmgRequest.reader = {set: user?.username};
        updateEvtDmgRequest.reportingDate = {set: new Date()};
        const r = await updateEventDamageByIdApi({variables: {id: eventDamage!.id!, data: updateEvtDmgRequest}});
        setEditedEventDamage(r.data?.updateOneEventDamage as Partial<EventDamage>);
      }

      if (Object.keys(notificationDamageUpdatedDatas.current).length) {
        const r = await updateNotificationDamageApi(
          {
            variables: {
              data: updateNotificationDamageRequest,
              dmgId: item.id!,
              notifId: notificationDamage!.notifId!,
            },
          },
        );
        setEditedNotificationDamage(r.data?.updateOneNotificationDamage as Partial<NotificationDamage>);
      }

      dmgUpdatedDatas.current = {};
      evtDmgUpdatedDatas.current = {};

      onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE);

      AppNotifications.success(t('message.success.damageUpdated'));
    } catch (e) {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  const fetchAllItemsEventsData = async () => {
    const fetchedItems: Array<EventDamage | NotificationDamage> = [];
    if (notificationDamage) {
      // Fetch all notification damages and associate
      const r = await getNotificationDamagesByDmgIdApi({
        variables: {
          notifId: notificationDamage.notifId!,
        },
        fetchPolicy: 'no-cache',
      });
      for (const dmg of allItems ?? []) {
        const fetchedItem = r.data?.notificationDamages.find(nd => (nd as NotificationDamage).dmgeId === (dmg as NotificationDamage).dmgeId);
        if (fetchedItem) {
          fetchedItems.push(fetchedItem as NotificationDamage);
        }
      }
    } else {
      // Fetch all event damages and associate
      for (const dmg of allItems ?? []) {
        if (dmg.__typename === 'EventDamage') {
          fetchedItems.push(dmg as EventDamage);
          continue;
        }

        const r = await getLatestEventDamageByDmgIdApi({
          variables: {
            dmgId: (dmg as Damage).id!,
          },
        });
        if (r.data?.findFirstEventDamage) {
          fetchedItems.push({
            ...r.data.findFirstEventDamage,
            damage: dmg as Damage,
          } as EventDamage);
        }
      }
    }

    setAllItemsFetched(fetchedItems);
  };

  useEffect(() => {
    if (allItems) {
      fetchAllItemsEventsData();
    }
  }, [allItems]);

  useEffect(() => {
    setEditedDamage(item);
    setEditedEventDamage(eventDamage);
    setEditedNotificationDamage(notificationDamage);
  }, [item, eventDamage, notificationDamage]);

  const handleBlur = () => {
    for (const key in dmgUpdatedDatas.current) {
      if (dmgUpdatedDatas.current[key as keyof Damage] !== editedDamage[key as keyof Damage]) {
        handleSave();
        return;
      }
    }

    for (const key in evtDmgUpdatedDatas.current) {
      if (evtDmgUpdatedDatas.current[key as keyof EventDamage] !== editedEventDamage?.[key as keyof EventDamage]) {
        handleSave();
        return;
      }
    }

    for (const key in notificationDamageUpdatedDatas.current) {
      if (notificationDamageUpdatedDatas.current[key as keyof NotificationDamage] !== editedNotificationDamage?.[key as keyof NotificationDamage]) {
        handleSave();
        return;
      }
    }
  };

  const renderItemSelector = () => allItemsFetched && <ClassPositionItemSelector<Partial<EventDamage | NotificationDamage>>
    items={
      allItemsFetched.map(e => (
        {
          item: e,
          itemClass: e.damage?.codeGroup?.class?.class,
          itemPosition: e.damage?.position,
          itemColor: e.qualReading?.integrityCondition?.color as string,
          id: e.damage?.id!,
        }))
    }
    selectedId={activeDamage?.id}
    onItemSelect={item => setActiveDamage(item.damage)}/>;

  return (
    <MultiplePanelsModal
      autoSaveId='modal-damage'
      header={<Header actions={isNew ? null : <Actions damage={item} disableDelete={!prms.damages.delete} onCreatedOrUpdated={onCreatedOrUpdated}/>} damage={item}/>}
      footer={
        <div className='flex flex-col w-full'>
          {renderItemSelector()}
          <Footer damage={item} onCancelClick={() => setIsModalOpened(false)}/>
        </div>
      }
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={() => true}
      onOpenChange={opened => {
        if (opened) {
          setIsModalOpened(true);
        } else {
          handleCloseModal();
        }
      }}
      {...restProps}
      isOpen={isModalOpened}
      onClose={handleCloseModal}
    >
      <div className='flex flex-col w-full overflow-auto'>
        <GeneralInfo readonly={!prms.damages.update} damage={editedDamage} onFieldUpdated={(f, v) => handleFieldUpdated('dmg', f, v)} onFieldBlur={handleBlur}/>
        <NotificationBlock damage={editedDamage}/>
      </div>
      <div className='flex flex-col w-full overflow-auto'>
        {(!!eventDamage || !!notificationDamage) && <MeasurementBlock readonly={!prms.damages.measurement} event={event!} damage={item} eventDamage={eventDamage} notificationDamage={notificationDamage} useCurrentDateAndUser={setCurrentDateAndUser} onFieldUpdated={(f, v) => handleFieldUpdated('meas', f, v)} onFieldBlur={handleBlur}/>}
        <PictureCarousel
          readonly={!prms.damages.measurement && !prms.damages.update}
          damage={editedDamage} event={event} onChanged={onPicturesChanged}
          onCarouselRender={handlePictureCarouselRender}/>
        <EventDamageHistory event={notificationDamage ? undefined : event} damage={item} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <DeleteDamageModal open={deleteDamageModalOpen} damage={item} changeDeleteDamageModalDisplay={changeDeleteDamageModalDisplay} onDamageDeleted={handleDamageDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
