import {useLazyQuery} from '@apollo/client';
import EventListPage from '@app/components/Common/Block/Event/EventListPage';
import {EventView, GetEventViewsBySiteStatusClassPlannedDateFiltersQuery} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENT_VIEWS_GET_ALL_BY_SITE_STATUS_CLASS_PLANNED_DATE_FILTERS} from '@app/graphql/requests';
import {TWorkpackDashboardFilters} from '@app/types/app';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {WorkPackBadge} from '../..';
import {ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS} from '@app/utils/constants';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {useTranslation} from 'react-i18next';

export default function WorkpackDashboardFilteredResultsPage() {
  const {state} = useLocation();
  const {t} = useTranslation();
  const [events, setEvents] = useState<EventView[]>();
  const [getDashboardFilteredResultsApi] = useLazyQuery<GetEventViewsBySiteStatusClassPlannedDateFiltersQuery>(WORKORDER_EVENT_VIEWS_GET_ALL_BY_SITE_STATUS_CLASS_PLANNED_DATE_FILTERS);

  useEffect(() => {
    console.log(state);
    const {sites, classes, plannedDateFilters, statuses} = (state ?? {}) as TWorkpackDashboardFilters;
    getDashboardFilteredResultsApi({
      variables: {
        sites,
        classes,
        plannedDateFilters,
        statuses,
      },
    }).then(queryResults => setEvents((queryResults.data?.eventViewsBySiteClassStatusPlannedDateFilters ?? []) as EventView[]));
  }, []);
  return (
    <SpinnerLoaderComponent isLoading={typeof events === 'undefined'}>
      <EventListPage badge={<WorkPackBadge/>} items={events} itemsLoading={typeof events === 'undefined'} title={t('label.results')} route={ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS}/>
    </SpinnerLoaderComponent>
  );
}
