import {useQuery} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import InputLabelWrapper from '@app/components/Common/Form/InputLabelWrapper/InputLabelWrapper';
import {GetAllRefEventTypesQuery, GetAllRefFlocCatalogsQuery, RefEventType, RefFlocCatalogs} from '@app/graphql/__types__/graphql';
import {WORKORDER_REF_EVENT_TYPES_GET_MANY} from '@app/graphql/requests';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import {EFLOC_CATALOGS_CATEGORY} from '@app/utils/enums';
import {RadInput, RadFormField, RadDatePicker} from '@holis/react-ui/rad';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AppListItem} from '@app/components/Common/Form/list-item';
import AppCombobox from '@app/components/Common/Form/AppCombobox';
import _ from 'lodash';

export default function Form() {
  const {t} = useTranslation();

  const [types, setTypes] = useState<AppListItem[]>([]);
  const [plannerGroups, setPlannerGroups] = useState<AppListItem[]>([]);
  const [mainWorkCenters, setMainWorkCenters] = useState<AppListItem[]>([]);

  const {loading: typesLoading} = useQuery<GetAllRefEventTypesQuery>(WORKORDER_REF_EVENT_TYPES_GET_MANY, {
    onCompleted(data) {
      setTypes(_.sortBy(data.refEventTypes.map((type: Partial<RefEventType>) => ({
        label: `[${type.type}] - ${type.description}`,
        value: type.id,
      })), ['label']));
    },
  });
  const {loading: flocCatalogsLoading} = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY, {
    onCompleted(data) {
      setPlannerGroups(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.PLAN_GROUP).map((plannerGroup: Partial<RefFlocCatalogs>) => ({
        label: `[${plannerGroup.code}] - ${plannerGroup.description}`,
        value: plannerGroup.id,
      })));

      setMainWorkCenters(data.findManyRefFlocCatalogs.filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.MAIN_WORK_CENTER).map((mainWorkCenter: Partial<RefFlocCatalogs>) => ({
        label: `[${mainWorkCenter.code}] - ${mainWorkCenter.description}`,
        value: mainWorkCenter.id,
      })));
    },
    variables: {
      orderBy: [
        {code: {sort: 'asc'}},
        {description: {sort: 'asc'}},
      ],
    },
  });

  return (
    <div className='flex flex-col gap-6 pl-0.5 pr-4 pb-0.5'>
      {/* Identification */}
      <div className='flex flex-col gap-4'>

        <FormGroupHeader>
          {t('label.identification')}
        </FormGroupHeader>
        <div className='grid grid-cols-2 gap-2'>
          {/* Description */}
          <RadFormField
            name='description'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.description')}>
                  <RadInput
                    placeholder={t('label.description')}
                    {...field}
                    onChange={e => {
                      field.onChange(e);
                    }}/>
                </InputLabelWrapper>
              )
            }/>

          {/* Type */}
          <RadFormField
            name='typeId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.type')}>
                  <AppCombobox
                    required
                    items={types}
                    loading={typesLoading}
                    placeholder={t('label.selectType')}
                    {...field}
                  />
                </InputLabelWrapper>
              )
            }/>
        </div>
        <div className='grid grid-cols-2 gap-2'>
          {/* Planned Date */}
          <RadFormField
            name='plannedDate'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper isRequired fieldState={fieldState} label={t('label.inspectionPlannedDate')}>
                  <RadDatePicker
                    placeholder={t('label.inspectionPlannedDate')}
                    {...field}
                    onDateSelected={date => {
                      field.onChange(date);
                    }}/>
                </InputLabelWrapper>
              )
            }/>
        </div>
      </div>

      {/* Responsabilities */}
      <div className='flex flex-col gap-4'>
        <FormGroupHeader>
          {t('label.responsabilities')}
        </FormGroupHeader>

        <div className='flex gap-2'>
          {/* Planner group */}
          <RadFormField
            name='plannerGroupId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.plannerGroup')} className='w-full'>
                  <AppCombobox
                    {...field}
                    items={plannerGroups}
                    loading={flocCatalogsLoading}
                    placeholder={t('label.selectPlannerGroup')}
                  />
                </InputLabelWrapper>
              )
            }/>

          {/* Main workcenter */}
          <RadFormField
            name='mainWorkCenterId'
            render={
              ({field, fieldState}) => (
                <InputLabelWrapper fieldState={fieldState} label={t('label.mainWorkCenter')} className='w-full'>
                  <AppCombobox
                    {...field}
                    items={mainWorkCenters}
                    loading={flocCatalogsLoading}
                    placeholder={t('label.selectMainWorkCenter')}
                  />
                </InputLabelWrapper>
              )
            }/>
        </div>
      </div>
    </div>
  );
}
