import PermissionSchedulingPlans from './PermissionSchedulingPlans';
import PermissionEvents from './PermissionEvents';
import PermissionAdmin from './PermissionAdmin';
import PermissionCmls from './PermissionCmls';
import PermissionDamages from './PermissionDamages';
import PermissionInspectionPoints from './PermissionInspectionPoints';
import {Permission} from '@app/graphql/__types__/graphql';
import PermissionFlocs from './PermissionFlocs';
import PermissionNotifications from './PermissionNotifications';
import PermissionDocs from './PermissionDocs';
import PermissionDrawings from './PermissionDrawings';
import PermissionMenu from './PermissionMenu';

export type PermissionsState = {
  loaded: boolean;
  error?: string;
};
export default class UserPermissions {
  public state: PermissionsState = {loaded: false};
  public menu: PermissionMenu = new PermissionMenu();
  public schedulingPlans: PermissionSchedulingPlans = new PermissionSchedulingPlans();
  public events: PermissionEvents = new PermissionEvents();
  public cmls: PermissionCmls = new PermissionCmls();
  public damages: PermissionDamages = new PermissionDamages();
  public inspectionPoints: PermissionInspectionPoints = new PermissionInspectionPoints();
  public admin: PermissionAdmin = new PermissionAdmin();
  public flocs: PermissionFlocs = new PermissionFlocs();
  public notifications: PermissionNotifications = new PermissionNotifications();
  public docs: PermissionDocs = new PermissionDocs();
  public drawings: PermissionDrawings = new PermissionDrawings();
  constructor(dbPermissions?: Permission[]) {
    dbPermissions?.forEach(permission => {
      this[permission.resource as keyof UserPermissions] = JSON.parse(permission.permissions);
    });
  }
}
