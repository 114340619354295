import PanelFilters from '@app/components/Common/Panel/PanelFilters';
import SearchBar from '@app/components/Common/SearchBar';
import {PictureCategory} from '@app/types/app';
import {RadButton} from '@holis/react-ui/rad';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusSquare} from 'react-icons/lu';

export type PicturePageHeaderProps = Readonly<{
	filters?: PictureCategory[];
	setFilters?: (filters: PictureCategory[]) => void;
	search?: (search: string) => void;
  uploadInputRef?: React.RefObject<HTMLInputElement>;
  readonly?: boolean;
  canAdd?: boolean;
}>;

export default function PicturePageHeader({
  filters = [],
  setFilters,
  search,
  uploadInputRef,
  readonly,
  canAdd,
}: PicturePageHeaderProps) {
  const {t} = useTranslation();

  const handleUploadBtnclick = () => {
    uploadInputRef?.current?.click();
  };

  return (
    <div className='flex flex-row-reverse items-center gap-2'>
      {(uploadInputRef && canAdd !== false) && <RadButton variant='outline' disabled={readonly} className='text-black' onClick={handleUploadBtnclick}><LuPlusSquare className='mr-1'/> {t('label.addPicture')}</RadButton>}
      <PanelFilters<PictureCategory>
        isMultiple
        value={filters}
        items={[
          {label: t('label.general'), value: 'general'},
          {label: t('label.inspectionPoint'), value: 'inspectionPoint'},
          {label: t('label.damages'), value: 'damage'},
          {label: t('label.cmls'), value: 'cml'},
        ]}
        onChange={items => setFilters?.(items.map(i => i.value))}
      />
      <SearchBar onChange={e => search?.(e.target.value)}/>
    </div>
  );
}
