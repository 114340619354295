import {EventCountByStatusSitePlannedDateResponse, StatusWorkOrder} from '@app/graphql/__types__/graphql';
import {EEventPerformanceDashboardPlannedDateFilter} from '@app/utils/enums';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  plannedDateFilters?: EEventPerformanceDashboardPlannedDateFilter[];
  classFilters?: string[];
  data?: EventCountByStatusSitePlannedDateResponse[];
  filteredData?: EventCountByStatusSitePlannedDateResponse[];
  eventStatuses?: StatusWorkOrder[];
  availableStatuses?: string[];
}

const initialState = {
  plannedDateFilters: undefined,
  data: undefined,
  eventStatuses: undefined,
  availableStatuses: undefined,
  classFilters: undefined,
};

type Actions = {
  setPlannedDateFilters: (plannedDateFilters?: EEventPerformanceDashboardPlannedDateFilter[]) => void;
  setClassFilters: (classFilters?: string[]) => void;
  setData: (data?: EventCountByStatusSitePlannedDateResponse[]) => void;
  setFilteredData: (filteredData?: EventCountByStatusSitePlannedDateResponse[]) => void;
  setEventStatuses: (eventStatuses?: StatusWorkOrder[]) => void;
  setAvailableStatuses: (availableStatuses?: string[]) => void;
  getStatusColor: (status?: string) => string;
  getStatusStage: (status?: string) => string;
};

export type EventPerformanceDashboardState = State & Actions;

export const useEventPerformanceDashboardStore = create<EventPerformanceDashboardState>()(
  immer((set, get) => ({
    ...initialState,
    setPlannedDateFilters(plannedDateFilters) {
      set({plannedDateFilters});
    },
    setClassFilters(classFilters) {
      set({classFilters});
    },
    setData(data) {
      set({data});
    },
    setFilteredData(filteredData) {
      set({filteredData});
    },
    setEventStatuses(eventStatuses) {
      set({eventStatuses});
    },
    getStatusColor(status) {
      const {eventStatuses} = get();
      return eventStatuses?.find(evtStatus => evtStatus.status === status)?.displayColor ?? 'white';
    },
    getStatusStage(status) {
      const {eventStatuses} = get();
      return eventStatuses?.find(evtStatus => evtStatus.status === status)?.stage ?? '';
    },
    setAvailableStatuses(availableStatuses) {
      set({availableStatuses});
    },
  })),
);
