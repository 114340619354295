import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {EventCountByStatusSitePlannedDateResponse} from '@app/graphql/__types__/graphql';
import {useEventPerformanceDashboardStore} from '@app/stores/event/performanceDashboard';
import {ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS} from '@app/utils/constants';
import {renderCustomizedPieChartLabel} from '@app/utils/functions';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {PieChart, Pie, Cell, ResponsiveContainer} from 'recharts';

type TBarChartData = {
  status: string,
  counter: number,
}

export default function EventProgressPieChart() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {getStatusColor, filteredData, classFilters, plannedDateFilters} = useEventPerformanceDashboardStore();
  const [chartData, setChartData] = useState<TBarChartData[]>();

  const onClickPieCell = (status: string) => {
    navigate(ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS, {
      state: {
        statuses: [status],
        classes: classFilters,
        plannedDateFilters,
      },
    });
  };

  useEffect(() => {
    if (typeof filteredData !== 'undefined') {
      const chartDataToBuild: Record<string, TBarChartData> = {};
      filteredData?.forEach((item: EventCountByStatusSitePlannedDateResponse) => {
        const {status, counter} = item;
        if (!Object.keys(chartDataToBuild).includes(status)) {
          chartDataToBuild[status] = {
            status,
            counter: 0,
          };
        }

        chartDataToBuild[status].counter += counter;
      });
      setChartData(Object.values(chartDataToBuild));
    }
  }, [filteredData]);

  return (
    <div className='flex flex-col gap-2 w-full h-full items-center'>
      <h2 className='text-sm font-bold'>{t('label.progress')}</h2>
      <SpinnerLoaderComponent className='h-[200px]' isLoading={typeof chartData === 'undefined'}>
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart width={400} height={400}>
            <Pie
              data={chartData}
              cx='50%'
              cy='50%'
              labelLine={false}
              label={renderCustomizedPieChartLabel}
              outerRadius={80}
              fill='#8884d8'
              dataKey='counter'
            >
              {chartData?.map(({status}) => (
                <Cell key={`cell-${status}`} className='hover:cursor-pointer hover:opacity-85' name={t(`label.eventStatuses.${status.toLowerCase()}`)} fill={getStatusColor(status)} onClick={() => onClickPieCell(status)}/>
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </SpinnerLoaderComponent>
    </div>
  );
}
