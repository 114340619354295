import {useTranslation} from 'react-i18next';
import PlannedDateFilters from './components/PlannedDateFilters';
import EventCounterBarChart from './components/EventCounterBarChart';
import {useLazyQuery} from '@apollo/client';
import {GetWorkorderEventsCounterByStatusUserSitePlannedDateQuery, EventCountByStatusSitePlannedDateResponse, StatusWorkOrder, GetAllEventStatusesQuery} from '@app/graphql/__types__/graphql';
import {APP_EVENT_STATUSES_GET_MANY, WORKORDER_EVENTS_GET_COUNTER_BY_STATUS_USER_SITE_PLANNED_DATE} from '@app/graphql/requests';
import {useEffect} from 'react';
import {useEventPerformanceDashboardStore} from '@app/stores/event/performanceDashboard';
import EventProgressPieChart from './components/EventProgressPieChart';
import {checkPlannedDateFilters} from '@app/utils/functions';
import ClassFilters from './components/ClassFilters';
import {useDataStore} from '@app/stores/data';

export default function WorkPackDashboardPage() {
  const {t} = useTranslation();
  const {setData, setEventStatuses, data, setFilteredData, plannedDateFilters, classFilters} = useEventPerformanceDashboardStore();
  const {selectedSites} = useDataStore();
  const [getWorkorderDataApi] = useLazyQuery<GetWorkorderEventsCounterByStatusUserSitePlannedDateQuery>(WORKORDER_EVENTS_GET_COUNTER_BY_STATUS_USER_SITE_PLANNED_DATE);
  const [getEventStatusesApi] = useLazyQuery<GetAllEventStatusesQuery>(APP_EVENT_STATUSES_GET_MANY);

  const getData = () => {
    getWorkorderDataApi({
      fetchPolicy: 'no-cache',
    }).then(queryResults => {
      setData((queryResults.data?.eventCountBySiteStatusPlannedDate ?? []) as EventCountByStatusSitePlannedDateResponse[]);
    });
    getEventStatusesApi({
      fetchPolicy: 'no-cache',
    }).then(queryResults => setEventStatuses((queryResults.data?.statusWorkOrders ?? []) as StatusWorkOrder[]));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (typeof data !== 'undefined' && typeof classFilters !== 'undefined' && typeof selectedSites !== 'undefined') {
      let filteredData = [...(data ?? [])];
      if (Array.isArray(plannedDateFilters) && plannedDateFilters.length) {
        filteredData = filteredData.filter(item => item.plannedDateFilter && checkPlannedDateFilters(plannedDateFilters, item.plannedDateFilter!));
      }

      if (Array.isArray(classFilters)) {
        filteredData = filteredData.filter(item => item.class && classFilters.includes(item.class!));
      }

      filteredData = filteredData.filter(item => selectedSites.includes(item.site));

      setFilteredData(filteredData);
    }
  }, [data, plannedDateFilters, classFilters, selectedSites]);

  return (
    <div className='flex flex-col h-full w-full px-4 capitalize gap-2'>
      <h2 className='text-lg font-semibold h-10 mt-4'>{t('label.performanceDashboard')}</h2>
      <PlannedDateFilters/>
      <div className='flex gap-2 flex-1'>
        <div className='flex-1 border mb-4'>
          <EventCounterBarChart/>
        </div>
        <div className='flex flex-col pl-2 justify-between h-full max-h-[500px] w-[250px] items-center'>
          <EventProgressPieChart/>
          <ClassFilters/>
        </div>
      </div>
    </div>
  );
}
