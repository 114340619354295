import {useLazyQuery, useMutation} from '@apollo/client';
import {CreateEventWorkflowMutation, EventView, GenerateEventReportsMutation, GetEventViewsByStageQuery, GetOneParameterByCodeQuery, Parameter, UpdateEventByIdMutation, UserInfo} from '@app/graphql/__types__/graphql';
import {APP_PARAMTERS_GET_ONE_BY_CODE, WORKORDER_EVENT_VIEWS_GET_ALL_BY_STAGE, WORKORDER_EVENT_WORKFLOWS_CREATE, WORKORDER_EVENTS_GENERATE_REPORTS, WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import {useEventStore} from '@app/stores/event';
import {EWORKORDER_EVENT_STAGE, EWORKORDER_EVENT_STATUS} from '@app/utils/enums';
import {RadBadge, RadButton, RadCheckbox} from '@holis/react-ui/rad';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuBan, LuFolderCheck, LuPlusCircle, LuUserCheck} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';
import UserAssignModal from '../UserAssignModal';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import {tailwindColorToBgFgStyle, textEllipsisCellRenderer, textEllipsisWrapper, ensureDefaultGridLayout} from '@app/utils/functions';
import {CellRenderer, GridColumnSortFn, GridProps} from '@holis/react-ui';
import YearClassFilterSideBar from './components/YearClassFilterSideBar';
import {APP_AUTO_NAMING, APP_PARAMETER_ALLOW_NEW_EVENT, FORMAT_DATE_EU, GRID_DEFAULT_LAYOUT_EVENT, OBJ_NEW_ID} from '@app/utils/constants';
import EventNewModal from '../EventNewModal';
import AppGrid from '@app/components/Common/AppGrid';

export type TEventListPage = Readonly<{
  stage?: string;
  title: string;
  items?: EventView[];
  itemsLoading?: boolean;
  badge: React.ReactNode;
  route: string;
  hasYearClassFilter?: boolean;
  gridProps?: Partial<GridProps<Partial<EventView>>>;
  columnsAlwaysVisible?: string[];
}>;

export const NO_YEAR = -1;
export const NO_CLASS = '';

export default function EventListPage({stage, title, hasYearClassFilter, gridProps, items, itemsLoading}: TEventListPage) {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {setActiveEvent, activeEvent} = useEventStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [events, setEvents] = useState<Partial<EventView>[]>();
  const [filteredEvents, setFilteredEvents] = useState<Partial<EventView>[]>();
  const {t} = useTranslation();
  const GRID_NAME = `events${t(title)}Grid`;
  const [wrkoAssignInProgress, setWrkoAssignInProgress] = useState(false);
  const [generateReportInProgress, setGenerateReportInProgress] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const {setAssignModalOpened, assignModalOpened} = useEventStore();

  const [getEventsApi, {loading}] = useLazyQuery<GetEventViewsByStageQuery>(WORKORDER_EVENT_VIEWS_GET_ALL_BY_STAGE);
  const [updateEventByIdApi] = useMutation<UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);
  const [createEventWorkflowApi] = useMutation<CreateEventWorkflowMutation>(WORKORDER_EVENT_WORKFLOWS_CREATE);
  const [generateEventReportsApi] = useMutation<GenerateEventReportsMutation>(WORKORDER_EVENTS_GENERATE_REPORTS);
  const [hasNewBtn, setHasNewBtn] = useState<boolean>(false);
  const [getAppParamterApi] = useLazyQuery<GetOneParameterByCodeQuery>(APP_PARAMTERS_GET_ONE_BY_CODE);

  const getEvents = async () => {
    if (selectedSites?.length) {
      const queryResults = await getEventsApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ], ...(stage ? {stage} : {}),
        },
        fetchPolicy: 'no-cache',
      });
      setEvents((queryResults.data?.eventViews ?? []) as Partial<EventView>[]);
    } else {
      setEvents([]);
    }
  };

  const createNewEvent = () => {
    setActiveEvent({
      id: OBJ_NEW_ID,
      event: APP_AUTO_NAMING,
      status: EWORKORDER_EVENT_STATUS.PREP_PROCESSING,
      releasedDate: new Date(),
      __typename: 'Event',
    });
  };

  const cancelGenerateRports = () => {
    setGenerateReportInProgress(false);
    setSelectedEvents([]);
  };

  useEffect(() => {
    if (!Array.isArray(items) && !itemsLoading) {
      getEvents();
    } else if (!itemsLoading) {
      setEvents(items);
    }
  }, [selectedSites, stage, items]);

  useEffect(() => {
    setFilteredEvents(events);
  }, [events]);

  const selectUnselectEvent = (id: number) => {
    if (selectedEvents.includes(id)) {
      setSelectedEvents(selectedEvents.filter(eid => eid !== id));
    } else {
      setSelectedEvents([...selectedEvents, id]);
    }
  };

  const onCreateNewEvent = () => {
    getEvents();
  };

  const generateReports = () => {
    startLoading();
    generateEventReportsApi({
      variables: {
        ids: selectedEvents,
      },
    }).then(queryResult => {
      if (queryResult.data?.generateEventReports) {
        AppNotifications.success(t('message.report.generation.requestsSaved'));
        cancelGenerateRports();
      } else {
        AppNotifications.error(t('message.error.default.title'));
      }
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(stopLoading);
  };

  const canAssignEvent = (event: EventView) => event.status === EWORKORDER_EVENT_STATUS.EXEC_ASSIGN_WORKPACK_TECHNICIAN || event.status === EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION;

  const filterItemsByYearClasses = (yearClasses?: Record<number, Array<string | null>>) => {
    setFilteredEvents(!yearClasses || Object.keys(yearClasses).length ? events?.filter(evt => {
      const evtYear = evt.inspectionDate ? moment(evt.inspectionDate!).toDate().getFullYear() : NO_YEAR;
      const evtClass = evt.schedulingTag_techClass_class_class ?? null;
      const evtClassDesc = evt.schedulingTag_techClass_class_description ?? '';
      const evtClassTitle = evtClass ? `${evtClass} - ${evtClassDesc}` : null;
      return !yearClasses || !Object.keys(yearClasses).length || (evtYear && yearClasses[evtYear] && yearClasses[evtYear].includes(evtClassTitle));
    }) : events);
  };

  const checkNewBtnDisplay = () => {
    getAppParamterApi({
      variables: {
        code: APP_PARAMETER_ALLOW_NEW_EVENT,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => setHasNewBtn((queryResult.data?.parameter as Parameter | null)?.value === 'true'));
  };

  useEffect(() => {
    if (!gridProps) {
      ensureDefaultGridLayout(GRID_NAME, GRID_DEFAULT_LAYOUT_EVENT);
    }

    checkNewBtnDisplay();
  }, []);

  const handleAssignEvents = async (u: UserInfo) => {
    startLoading();
    for (const eventId of selectedEvents) {
      const previousEventStatus = events?.find(e => e.id === eventId)?.status;
      // Set event assigned to user
      await updateEventByIdApi({
        variables: {
          id: eventId,
          data: {
            inspectorId: {set: u.username},
            status: {
              set: EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION,
            },
          },
        },
      });
      // Create workflow entry
      await createEventWorkflowApi?.({
        variables: {
          data: {
            userLogin: u?.username,
            actionDate: new Date(),
            status: `${previousEventStatus}|${EWORKORDER_EVENT_STATUS.EXEC_SEND_EXECUTION}`,
            wrkoId: eventId,
            description: t('label.eventActions.descriptions.assign_workpack', {user: u.username}),
          },
        },
      });
    }

    AppNotifications.success(t('message.success.multiplEventAssigned', {count: selectedEvents.length}));
    getEvents();
    stopLoading();
    setSelectedEvents([]);
    setWrkoAssignInProgress(false);
    setAssignModalOpened(false);
  };

  const renderActionCell: CellRenderer<Partial<EventView>> = (_, node) => {
    const evt = node.data as EventView;
    const canAssign = wrkoAssignInProgress && canAssignEvent(evt);
    return (
      <div className='!flex gap-4 items-center'>
        {generateReportInProgress && <RadCheckbox
          aria-label='Select row'
          className='w-4 h-4 align-bottom'
          checked={selectedEvents.includes(evt.id)}
          onClick={() => {
            selectUnselectEvent(evt.id);
          }}
        >
          <MdOpenInNew size={18}/>
        </RadCheckbox>}
        {wrkoAssignInProgress && <RadCheckbox
          disabled={!canAssign}
          className={canAssign ? '' : '!opacity-10'}
          checked={selectedEvents.includes(evt.id)}
          onClick={() => {
            selectUnselectEvent(evt.id);
          }}
        />}
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={e => {
            const eventNumber = evt.event!;
            if (e.ctrlKey || e.metaKey) {
              window.open(location.pathname + '/' + eventNumber, '_blank');
              return;
            }

            navigate(`./${eventNumber}`, {relative: 'path'});
          }}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      </div>
    );
  };

  const sortDate: GridColumnSortFn<Partial<EventView>> = (a, b) => {
    const aTime = a[0] ? new Date(a[0] as string).getTime() : 0;
    const bTime = b[0] ? new Date(b[0] as string).getTime() : 0;
    return aTime - bTime;
  };

  const gProps: GridProps<Partial<EventView>> = {
    columns: [
      {
        field: undefined,
        title: '',
        cellRenderer: renderActionCell,
        defaultState: {
          width: 77.5,
        },
        filter: false,
        cannotHide: true,
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'set',
        cellRenderer: 'status',
        defaultState: {
          width: 117,
        },
        cannotHide: true,
      },
      {
        field: 'event',
        title: t('label.event'),
        type: 'string',
        defaultState: {width: 129},
        cellRenderer: 'textBold',
        cannotHide: true,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {width: 289},
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        defaultState: {width: 294},
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        defaultState: {width: 445},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_sector_site_site',
        title: t('label.site'),
        type: 'set',
        defaultState: {width: 77.5},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_sector_sector',
        title: t('label.sector'),
        type: 'string',
        defaultState: {width: 77.5},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_techClass_class_class',
        title: t('label.class'),
        type: 'string',
        defaultState: {width: 77.5},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'set',
        defaultState: {width: 120},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'set',
        defaultState: {
          width: 77.5,
          hidden: false,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'priority',
        title: t('label.priority'),
        type: 'string',
        defaultState: {width: 80},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'batchNumber',
        title: t('label.batchNumber'),
        type: 'string',
        defaultState: {width: 120},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'plannedDate',
        title: t('label.plannedDate'),
        type: 'date',
        cellRenderer: 'date',
        defaultState: {width: 134},
        sortFn: sortDate,
      },
      {
        field: 'inspectionDate',
        title: t('label.inspectionDate'),
        type: 'date',
        cellRenderer: 'date',
        defaultState: {width: 137},
        sortFn: sortDate,
      },
      {
        field: 'inspectorId',
        title: t('label.inspector'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: 'inspector',
      },
      {
        field: 'releasedDate',
        title: t('label.releasedDate'),
        type: 'date',
        cellRenderer: 'date',
        defaultState: {
          width: 80,
          hidden: true,
        },
        sortFn: sortDate,
      },
      {
        field: 'reviewerId',
        title: t('label.reviewer'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: 'reviewer',
      },
      {
        field: 'approverId',
        title: t('label.approver'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: 'approver',
      },
      {
        field: 'reportNumber',
        title: t('label.reportNumber'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'reportDescription',
        title: t('label.reportDescription'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'reportingDate',
        title: t('label.reportingDate'),
        type: 'date',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: 'date',
        sortFn: sortDate,
      },
      {
        field: 'schedulingTag_mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        defaultState: {width: 77.5,
          hidden: true},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'set',
        defaultState: {width: 77.5,
          hidden: true},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'externalId',
        title: t('label.externalId'),
        type: 'string',
        defaultState: {width: 77.5,
          hidden: true},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {width: 80, hidden: true},
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'code',
        title: t('label.shortCode'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'schedulingTag_classSece',
        title: t('label.classSece'),
        type: 'string',
        defaultState: {
          width: 100,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'string',
        defaultState: {
          width: 77.5,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'notes',
        title: t('label.notes'),
        type: 'string',
        defaultState: {
          width: 120,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
    ],
    data: filteredEvents ?? [],
    cellRenderers: {
      status(val, node) {
        if (!val) {
          return '';
        }

        const viewDatas = node.data;

        return (
          <RadBadge variant='outline' className='py-1 font-normal text-nowrap min-w-[100px] justify-center max-w-full' style={tailwindColorToBgFgStyle(viewDatas.status_displayColor)} title={viewDatas.status_description as string}>
            <div className='text-ellipsis overflow-hidden'>
              {viewDatas.status_description}
            </div>
          </RadBadge>
        );
      },
      approver: (val, node) => textEllipsisWrapper(!val ? '' : `${node.data.approverLastName ?? ''} ${node.data.approverFirstName ?? ''} (${node.data.approverId})`),
      inspector: (val, node) => textEllipsisWrapper(!val ? '' : `${node.data.inspectorLastName ?? ''} ${node.data.inspectorFirstName ?? ''} (${node.data.inspectorId})`),
      reviewer: (val, node) => textEllipsisWrapper(!val ? '' : `${node.data.reviewerLastName ?? ''} ${node.data.reviewerFirstName ?? ''} (${node.data.reviewerId})`),
      date: (val: string) => textEllipsisWrapper(val ? moment(val).format(FORMAT_DATE_EU) : ''),
      textBold: (val: string) => textEllipsisWrapper(val ? <span className='font-bold'>{val}</span> : ''),
    },
    onRowClick(node) {
      const viewDatas = node.data as EventView;
      if (wrkoAssignInProgress && !canAssignEvent(viewDatas)) {
        return;
      }

      selectUnselectEvent(viewDatas.id);
    },
  };

  return (
    <>
      <div className='flex flex-row h-full w-full overfloww-hidden'>
        {hasYearClassFilter && !!events?.length && <YearClassFilterSideBar items={events!} filterItemsByYearClasses={filterItemsByYearClasses}/>}
        <div className='h-full flex-1 overflow-auto'>
          <AppGrid
            canSelect
            isLoading={loading}
            name={GRID_NAME}
            title={t(title)}
            {...gProps}
            {...gridProps}
            actions={<>
              {hasNewBtn && <RadButton
                size='sm'
                className='flex gap-1 text-sm bg-sky-500 text-white hover:bg-sky-600'
                onClick={createNewEvent}
              >
                <LuPlusCircle/> {t('label.addEvent')}
              </RadButton>}
              {/* TEMPORARY DISABLED !wrkoAssignInProgress && !generateReportInProgress && <RadButton className='h-8 rounded-md px-3 gap-2 text-sm' onClick={() => setGenerateReportInProgress(true)}><LuFolderCheck/> {t('label.generateWorkpackReports')}</RadButton> */}
              {
                generateReportInProgress && (
                  <>
                    <RadButton
                      size='sm'
                      className='flex gap-1 text-sm'
                      disabled={!selectedEvents.length}
                      onClick={generateReports}
                    >
                      <LuFolderCheck/> {t('label.generateCountWorkpackReports', {count: selectedEvents.length})}
                    </RadButton>
                    <RadButton
                      size='sm'
                      variant='secondary'
                      className='flex gap-1 text-sm'
                      onClick={() => {
                        cancelGenerateRports();
                      }}>
                      <LuBan/> {t('label.cancelWorkpackReportGeneration')}
                    </RadButton>
                  </>
                )
              }
              {stage === EWORKORDER_EVENT_STAGE.EXECUTION && !wrkoAssignInProgress && !generateReportInProgress && (
                <RadButton
                  size='sm'
                  className='flex gap-1 text-sm'
                  onClick={() => setWrkoAssignInProgress(true)}
                >
                  <LuUserCheck/> {t('label.assignReassignWorkpacks')}
                </RadButton>
              )}
              {
                wrkoAssignInProgress && (
                  <>
                    <RadButton
                      size='sm'
                      className='flex gap-1 text-sm'
                      disabled={!selectedEvents.length}
                      onClick={() => setAssignModalOpened(true)}
                    >
                      <LuUserCheck/> {t('label.assignCountWorkpacks', {count: selectedEvents.length})}
                    </RadButton>
                    <RadButton
                      size='sm'
                      variant='secondary'
                      className='flex gap-1 text-sm'
                      onClick={() => {
                        setSelectedEvents([]);
                        setWrkoAssignInProgress(false);
                      }}>
                      <LuBan/> {t('label.cancelWorkpacksAssignment')}
                    </RadButton>
                  </>
                )
              }
            </>}
          />
        </div>
      </div>
      <UserAssignModal opened={assignModalOpened} onClose={() => setAssignModalOpened(false)} onValidate={handleAssignEvents}/>
      {!!activeEvent && activeEvent.id === OBJ_NEW_ID && <EventNewModal
        isOpen onOpenChange={(isOpen: boolean) => !isOpen && setActiveEvent(undefined)} onCreated={onCreateNewEvent}/>}
    </>
  );
}
