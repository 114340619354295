
export const APP_QUERY_ACCESS_TOKEN = 'access_token';
export const OBJ_NEW_ID = -1;
export const NO_DRAWING_ID = -1;
export const pictureCategories = ['general', 'inspectionPoint', 'damage', 'cml'];
export const USER_SELECTED_SITES = 'user_selected_sites';
export const MARKUP_BACKGROUND_ID = 'MARKUP_BACKGROUND';
export const GRID_DEFAULT_LAYOUT_DWG = '{"state":{"treeState":{"columns":{"action":{"filter":false,"width":75},"id":{"hidden":true,"width":106},"sector_site_site":{"width":100},"sector_sector":{"width":105},"idwg":{"sort":"asc","width":250},"description":{"width":461},"type_type":{"width":135},"revision":{"width":115},"flagStatus":{"hidden":true,"width":170},"docName":{"width":400}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_DOC = '{"state":{"treeState":{"columns":{"action":{"filter":false,"width":91},"id":{"hidden":true,"width":80},"sector_site_site":{"width":109},"sector_sector":{"width":97},"document":{"width":261},"description":{"width":975},"type_code":{"width":103},"discipline_code":{"width":108},"catalog_code":{"hidden":true,"width":122},"phase_code":{"width":117},"stage_code":{"width":110},"docName":{"width":279},"revision":{"width":155},"originator_code":{"hidden":true,"width":80},"flagStatus":{"hidden":true,"width":80}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_IAN = '{"state":{"treeState":{"columns":{"action":{"filter":false,"width":60},"schedulingTag_sector_site_site":{"pinned":false,"width":100},"status":{"sort":"asc","pinned":false,"width":146},"notif":{"width":145},"externalId":{"pinned":false,"width":174},"type_notifType":{"pinned":false,"width":80},"schedulingTag_floc":{"pinned":false,"width":389},"description":{"width":354},"schedulingTag_techClass_techClass":{"pinned":false,"width":150},"creationDate":{"hidden":false,"width":127},"reportedBy":{"hidden":false,"width":129},"approvedBy":{"hidden":false,"width":123},"isTempRepair":{"width":127},"ffsRequired":{"width":111},"isBelowMawt":{"width":98},"isLeak":{"width":80},"plannerGroup_code":{"width":121},"mainWorkCenter_code":{"width":165},"plantWorkCenter_code":{"width":150},"consequence":{"hidden":false,"pinned":false,"width":125},"priority_code":{"width":80},"requiredEndDate":{"width":164},"event_event":{"width":164}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_DMG = '{"state":{"treeState":{"columns":{"id":{"hidden":true,"pinned":false,"width":101},"flagStatus":{"hidden":true,"pinned":false,"width":80},"idwg_idwg":{"hidden":true,"width":201},"coordinates3d":{"hidden":true,"width":155.73333333333332},"markerCoordinates3d":{"hidden":true,"width":155.89584350585938},"floc_techClass_class_class":{"hidden":true,"width":100},"display2d":{"hidden":true,"width":80},"display3d":{"hidden":true,"width":80},"coordinates2d":{"hidden":true,"width":80},"markerCoordinates2d":{"hidden":true,"width":80},"maxEndDate":{"hidden":true,"width":80},"action":{"filter":false,"width":75},"floc_floc":{"width":383},"description":{"width":418},"notif_notif":{"pinned":false,"width":186},"codeGroup_class_class":{"pinned":false,"width":155.73333333333332},"floc_sector_site_site":{"hidden":false,"pinned":false,"width":100.078125},"floc_sector_sector":{"hidden":false,"pinned":false,"width":100},"codeGroup_codeGroup":{"width":155.73333333333332},"part_part":{"width":155.73333333333332},"grid_grid":{"width":155.73333333333332},"accessibility_code":{"width":155.73333333333332},"notif_externalId":{"width":155.73333333333332},"notif_externalStatus":{"width":156.171875}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_FLOC = '{"state":{"treeState":{"columns":{"action":{"filter":false,"width":60},"floc":{"width":329},"description":{"width":428},"techClass_techClass":{"pinned":false,"width":117},"referenceDocument":{"width":249},"sector_sector":{"width":99},"processFunction_processFunction":{"pinned":false,"width":132},"grid_grid":{},"classSece":{"width":113},"parent_floc":{"hidden":false,"pinned":false,"width":332},"planningPlant_code":{"pinned":false,"width":121},"plantWorkCenter_code":{"pinned":false,"width":135},"plannerGroup_code":{"pinned":false,"width":110},"mainWorkCenter_code":{"pinned":false,"width":129},"strategy_strategy":{},"docName":{"pinned":false,"width":170},"integritySystem":{"pinned":false,"width":140},"classification":{"width":100},"legislation":{}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_EVENT = '{"state":{"treeState":{"columns":{"id":{"hidden":true,"width":80},"schedulingTag_classSece":{"hidden":true,"width":100},"schedulingTag_grid_grid":{"hidden":true,"width":77.5},"notes":{"hidden":true,"width":120},"releasedDate":{"hidden":true,"width":80},"reviewer":{"hidden":true,"width":80},"approver":{"hidden":true,"width":80},"reportNumber":{"hidden":true,"width":80},"reportDescription":{"hidden":true,"width":80},"reportingDate":{"hidden":true,"width":80},"plan_plan":{"hidden":true,"width":77.5},"schedulingTag_mainWorkCenter_code":{"hidden":true,"width":77.5},"schedulingTag_plannerGroup_code":{"hidden":true,"width":77.5},"externalId":{"hidden":true,"width":77.5},"action":{"filter":false,"width":77.5},"status":{"hidden":false,"pinned":false,"width":117},"event":{"hidden":false,"width":129},"code":{"hidden":false,"pinned":false,"width":158},"description":{"hidden":false,"width":289},"schedulingTag_floc":{"hidden":false,"width":294},"schedulingTag_description":{"width":445},"schedulingTag_sector_site_site":{"width":77.5},"schedulingTag_sector_sector":{"width":77.5},"schedulingTag_techClass_class_class":{"width":77.5},"schedulingTag_techClass_techClass":{"hidden":false,"width":120},"type_type":{"hidden":false,"width":77.5},"priority":{"width":80},"batchNumber":{"width":120},"plannedDate":{"hidden":false,"width":134},"inspectionDate":{"width":137},"inspector":{"hidden":false,"pinned":false,"width":274}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const RADIAN = Math.PI / 180;
